/* -------------------------
  Menu specific styles
  --------------------------- */
.menu {
  margin: 100px 0;
  @include breakpoint($tablet-portrait) {
    margin: 50px 0;
    &:first-child {
      margin-top: 100px; } }
  h2 {
    padding-bottom: 20px;
    border-bottom: 2px solid $grey-6;
    text-align: center;
    margin-bottom: 40px; }
  .row {
    margin-top: 30px;
    margin-bottom: 30px;
    @include breakpoint($phone) {
      margin-top: 0;
      margin-bottom: 0; }
    @include breakpoint($tablet-portrait) {
      margin-top: 0;
      margin-bottom: 0; } }
  .tile {
    @include clearfix;
    @include breakpoint($phone) {
      margin-bottom: 40px; }
    @include breakpoint($tablet-portrait) {
      margin-bottom: 40px; }
    img {
      border-radius: 100%;
      width: 110px;
      height: 110px;
      float: left; }
    .description {
      float: left;
      width: 60%;
      margin-left: 20px;
      @include breakpoint($phone) {
        float: none;
        width: auto;
        margin-left: 140px; }
      h3 {
        text-transform: none;
        letter-spacing: 1px;
        @include fontsize-rem(20px);
        @include breakpoint($phone) {
          margin-top: 0;
          line-height: 1.3em; } }
      p {
        @include fontsize-rem(14px); } }
    .price {
      float: right;
      color: $brown;
      @include lato-bold;
      @include fontsize-rem(18px);
      margin-top: 20px;
      @include breakpoint($phone) {
        float: none;
        margin-left: 140px;
        margin-top: 10px; } } } }
