/* -------------------------
  Forms
  --------------------------- */
input[type="text"], input[type="number"], input[type="email"], textarea {
  border: 2px solid $grey-9;
  width: 100%;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
  @include lato-bold;
  letter-spacing: 2px;
  @include fontsize-rem(14px);
  @include breakpoint($phone) {
    @include fontsize-rem(12px); }
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $purple; } }

textarea {
  min-height: 120px;
  text-align: left;
  resize: vertical; }
