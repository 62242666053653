/* -------------------------
  Home specific styles
  --------------------------- */
.wrapper {
  height: 100%; }
main {
  background-color: $white;
  position: relative;
  z-index: 1;
  margin-top: 60px; }
section {
  margin: 50px 0;
  @include breakpoint($phone) {
    margin: 25px 0; }
  &.start-section {
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    background: {
      image: url('../images/slider/slider.png');
      size: cover;
      position: center center; }
    display: table;
    .cell-text {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $white;
      h1 {
        color: $white;
        line-height: 140%;
        @include fontsize-rem(50px);
        letter-spacing: 6px;
        @include breakpoint($phone) {
          @include fontsize-rem(18px);
          line-height: 1.8em; }
        @include breakpoint($tablet-portrait) {
          @include fontsize-rem(40px); }
        &:after {
          display: block;
          height: 2px;
          width: 50px;
          background-color: $white;
          content: " ";
          margin: 3rem auto;
          @include breakpoint($phone) {
            margin: 2rem auto; } } }
      p {
        margin-top: 2rem;
        @include fontsize-rem(14px);
        @include open-sans-regular;
        line-height: 160%; } } }

  &.video-bg {
    background: none;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto; }
    .cell-text {
      position: relative;
      background: rgba(0,0,0,0.5); } }
  &.slider-section {
    background: none;
    .homepage-gallery {
      min-width: 100%;
      min-height: 100%;
      .gallery-cell {
        width: 100%;
        height: 500px;
        position: relative;
        background-size: cover;
        .cell-text {
          display: block;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          width: 100%; } }
      .flickity-page-dots {
        bottom: 80px;
        .dot {
          background: white; } } } }
  &.screen {
    margin: 0;
    margin-top: -214px;
    height: 100%; }

  header {
    text-align: center;
    h2 {
      line-height: 1.5em;
      &:after {
        content: " ";
        display: block;
        background-color: $grey-6;
        height: 2px;
        width: 50px;
        margin: 35px auto;
        @include breakpoint($phone) {
          margin: 20px auto; } } }
    p {
      @include open-sans-regular;
      color: $grey-6;
      line-height: 1.5em; } }

  &.last-offers {
    margin: 100px 0;
    article {
      @include clearfix;
      text-align: center;
      & > div:nth-child(3n+3) {
        &:after {
          content: " ";
          display: table;
          width: 100%;
          height: 1px;
          clear: both; } }
      .item-offer {
        margin: 37.5px 0; }
      .thumbnail {
        overflow: hidden;
        padding: 0;
        border: none;
        display: block;
        border-radius: 0;
        transition: all 0.5s;
        img {
          transition: all 0.5s;
          min-width: 100%; }
        &:hover {
          img {
            transform: scale(1.1);
            transition: all 0.5s; } } }
      .date {
        margin: 20px 0;
        @include lato-regular;
        color: $grey-9;
        text-transform: uppercase;
        @include fontsize-rem(14px);
        letter-spacing: 2px;
        @include breakpoint($phone) {
          @include fontsize-rem(12px); } }
      hr {
        border-color: $grey-6;
        width: 25px;
        border-width: 2px; }
      .title {
        line-height: 1.3em;
        margin-bottom: 0rem;
        @include breakpoint($phone) {
          line-height: 1.5em;
          width: 80%;
          margin: 0 auto; }
        @include breakpoint($tablet-portrait) {
          line-height: 1.5em;
          @include fontsize-rem(16px); }
        a {
          color: $grey-6;
          &:hover {
            color: purple;
            text-decoration: none; } } } } }

  &.menu-and-products {
    margin: 20px 0;
    @include clearfix;
    div.tile {
      float: left;
      width: 50%;
      position: relative;
      @include breakpoint($phone) {
        width: 100%; }
      .content {
        color: white;
        z-index: 10;
        height: 400px;
        width: 100%;
        background-color: transparent;
        transition: all 0.4s;
        text-align: center;
        padding: 100px 0;
        @include breakpoint($phone) {
          background-color: rgba($purple, 0.4); }
        @include breakpoint($tablet-portrait) {
          background-color: rgba($purple, 0.4); }
        figure {
          @include fontsize-rem(65px); }
        p {
          @include lato-ultra;
          @include fontsize-rem(13px);
          margin-top: 20px;
          color: white;
          text-transform: uppercase;
          letter-spacing: 2px; }
        h3 {
          color: white;
          letter-spacing: 2px;
          margin-top: 10px; }
        .more {
          display: inline-block;
          border: 2px solid white;
          padding: 10px 50px;
          color: white;
          text-transform: uppercase;
          @include fontsize-rem(11px);
          @include lato-bold;
          letter-spacing: 2px;
          margin-top: 50px;
          @include breakpoint($phone) {
            background: white;
            color: $grey-26; }
          @include breakpoint($tablet-portrait) {
            background: white;
            color: $grey-26; }
          &:hover {
            background: white;
            color: $grey-26;
            text-decoration: none; } } }
      &:hover {
        .content {
          background-color: rgba(27, 23, 28, 0.7); } }
      &.our-menu {
        background-image: url("../images/background/bg2.png");
        background-size: cover;
        min-height: 400px; }
      &.our-products {
        background-image: url("../images/background/bg2.png");
        background-size: cover;
        min-height: 400px; } } }

  &.reservation {
    margin: 100px 0 120px;
    form {
      margin: {
        top: 40px;
        bottom: 40px; }
      button {
        width: 100%;
        padding: 15px 30px; }
      p {
        text-align: center;
        @include open-sans-regular;
        color: $grey-6;
        line-height: 1.5em;
        @include fontsize-rem(14px);
        br {
          @include breakpoint($phone) {
            display: none; }
          @include breakpoint($tablet-portrait) {
            display: none; } } }
      .form-group {
        margin: 30px 0;
        @include breakpoint($phone) {
          margin: 0; }
        @include breakpoint($tablet-portrait) {
          margin: 0; }
        .col-md-3 {
          @include breakpoint($tablet-landscape) {
            margin-top: 30px; } } }
      div[class^='col-'] {
        @include breakpoint($phone) {
          margin-bottom: 15px; }
        @include breakpoint($tablet-portrait) {
          margin-bottom: 20px; } } } } }
