/* -------------------------
  Contact specific styles
  --------------------------- */
.contact {
  color: $grey-6;
  @include breakpoint($tablet-portrait) {
    text-align: center; }

  .social-links {
    @include breakpoint($phone) {
      margin: 30px 0; }
    @include breakpoint($tablet-portrait) {
      margin: 30px 0; } }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    &.contact-data {
      @include breakpoint($tablet-portrait) {
        text-align: center; }
      li {
        display: block;
        @include fontsize-rem(16px);
        @include open-sans-regular;
        margin-bottom: 0rem;
        @include breakpoint($phone) {
          margin: 15px 0; }
        @include breakpoint($tablet-portrait) {
          margin: 15px 0; }
        .icon {
          vertical-align: middle;
          @include fontsize-rem(18px);
          margin-right: 3px; }
        a {
          color: $brown;
          text-decoration: none; } } } }
  h4 {
    margin-top: 2.5rem;
    letter-spacing: 1px;
    @include breakpoint($tablet-portrait) {
      margin-bottom: 2.5em; } }
  .btn-purple {
    line-height: 2rem; } }
#map {
  height: 500px;
  width: 100%; }
