/* -------------------------
  Paginations
  --------------------------- */
nav.pagination-block {
  margin-top: 0rem;
  text-align: center;
  margin-bottom: 4rem;
  ul.pagination {
    margin-top: 0rem;
    text-align: center;
    li {
      float: none;
      display: inline-block;
      vertical-align: middle;
      a {
        border: 0;
        display: block;
        line-height: 100%;
        text-align: center;
        color: $grey-9;
        @include lato-bold;
        @include fontsize-rem(16px);
        span {
          margin-top: -0.5rem;
          display: block; }
        &:hover, &:active, &:focus {
          background: none;
          color: $grey-3; } }
      &.active {
        background: none;
        a {
          background: none;
          color: $grey-3;
          &:hover, &:active, &:focus {
            background: none;
            color: $grey-3; } } } } } }
