/* -------------------------
  Offer specific styles
  --------------------------- */
.offer {
  header {
    p {
      color: $grey-26;
      @include fontsize-rem(14px); } }
  article {
    margin: 3rem 0;
    img {
      width: 100%;
      margin-bottom: 3rem; }
    h4 {
      padding-bottom: 0.75rem;
      margin-bottom: 2rem;
      letter-spacing: 2px;
      border-bottom: 2px solid $grey-6; }
    p {
      color: $grey-26;
      @include fontsize-rem(14px);
      line-height: 1.5rem;
      margin-bottom: 1.5rem; } } }
