/*
  Theme Name: Flamenco
  Theme URI: http://flamenco.codecafe.md/
  Description: Flamenco is created by <a href="http://themeforest.net/user/codecoffee">codecoffee</a>.
  Version: 0.0.1
  Author: codecaffee
  Author URI: http://themeforest.net/user/codecoffee


  WARNING! DO NOT EDIT THIS FILE!

  To make it easy to update your theme, you should not edit the styles in this file. Instead use
  the global.css file to add your styles. You can copy a style from this file and paste it in
  global.css and it will override the style in this file. You have been warned!

  Codecaffe use Sass '7-1 pattern' http://sass-guidelin.es/
  Back to architecture, shall we? I usually go with what I call the 7-1 pattern: 7 folders, 1 file. Basically, you have all your partials stuffed into 7 different folders, and a single file at the root level (usually named main.scss) which imports them all to be compiled into a CSS stylesheet.

  base/
  components/
  layout/
  pages/
  generated/
  utils/
  vendors/

  Sass folder include next files

  sass/
  |
  |– base/
  |   |– _general.sass     # Global styles
  |   |– _typography.sass  # Typography rules
  |
  |– components/
  |   |– _buttons.sass     # Buttons
  |   |– _comments.sass    # Comments
  |   |– _gallery.sass     # Slider product style
  |   |– _logo.sass        # Logo
  |   |– _meta.sass        # Meta
  |   |– _paginations.sass # Paginations
  |   |– _share.sass       # Share
  |   |– _side-menu.sass   # Side menu
  |
  |- generated/
  |   |- _icons.sass       # Generated icon fonts
  |
  |– layout/
  |   |– _header.sass      # Header
  |   |– _footer.sass      # Footer
  |   |– _forms.sass       # Forms
  |
  |– pages/
  |   |– _home.sass        # Home specific styles
  |   |– _contact.sass     # Contact specific styles
  |   |– _404.sass         # 404 specific styles
  |   |– _about.sass       # About specific styles
  |   |– _cart.sass        # Cart specific styles
  |   |– _checkout.sass    # Checkout specific styles
  |   |– _elements.sass    # Elements specific styles
  |   |– _menu.sass        # Menu specific styles
  |   |– _mews.sass        # News specific styles
  |   |– _offer.sass       # Offer specific styles
  |   |– _shop.sass        # Shop specific styles
  |   |– _success.sass     # Success specific styles
  |
  |– utils/
  |   |– _variables.sass   # Sass Variables
  |   |– _colors.sass      # Colors brand
  |   |– _mixins.sass      # Sass Mixins
  |   |– _helpers.sass     # Class & placeholders helpers
  |
  |– vendors/
  |   |– bootstrap
  |      |- _base.sass     # Bootstrap base settings
  |      |- _custom.sass   # Bootstrap customs
  |   |– flickity
  |      |- _flickity.sass # Flickity slider styles
  |
  |
  `– global.sass            # Main Sass file


  TABLE OF CONTENTS:

  1.0 Base styles
    1.1 Typography rules
    1.2 Global styles
  2.0 Components style
    2.1 Buttons
    2.2 Comments
    2.3 Slider product style
    2.4 Logo
    2.5 Meta - category and tags
    2.6 Paginations
    2.7 Share
    2.8 Side menu
  3.0 Generated
    3.1 Generated icon fonts
  4.0 Layout style
    4.1 Header
    4.2 Footer
    4.3 Forms
  5.0 Pages
    5.1 Home specific styles
    5.2 Contact specific styles
    5.3 404 specific styles
    5.4 About specific styles
    5.5 Cart specific styles
    5.5 Checkout specific styles
    5.6 Elements specific styles
    5.7 Menu specific styles
    5.8 News specific styles
    5.9 Offer specific styles
    5.10 Shop specific styles
    5.11 Success specific styles
  6.0 Utils
    6.1 Sass Variables
    6.2 Colors brand
    6.3 Sass Mixins
    6.4 Class & placeholders helpers
  7.0 Vendors
    7.1 bootstrap
      7.1.1 Bootstrap base settings
      7.1.2 Bootstrap customs
    7.2 flickity
      7.2.1 Flickity slider styles
 */

// Bootstrap collapse navbar breakpoint
$grid-float-breakpoint: 769px

//Import breakpoints
@import ../../node_modules/breakpoint-sass/stylesheets/breakpoint

//Import fonts
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900)
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic)

// Include this if using an Icon Font
@import generated/icons

//Import vendor
@import vendor/bootstrap/base
@import vendor/bootstrap/custom
@import vendor/flickity/flickity

//Import utils
@import utils/helpers
@import utils/mixins
@import utils/colors
@import utils/variables

//Import base
@import base/typography
@import base/general

//Import components
@import components/buttons
@import components/comments
@import components/logo
@import components/paginations
@import components/share
@import components/side-menu
@import components/meta
@import components/gallery

//Import layout
@import layout/header
@import layout/forms
@import layout/footer

//Import pages
@import pages/about
@import pages/contact
@import pages/home
@import pages/offer
@import pages/menu
@import pages/news
@import pages/shop
@import pages/cart
@import pages/elements
@import pages/checkout
@import pages/404
@import pages/success
