/* -------------------------
  Global styles
  --------------------------- */
html, body {
  @include fontsize-rem(16px);
  @include open-sans-regular;
  height: 100%;
  @include breakpoint($phone) {
    @include fontsize-rem(15px); } }
a, button {
  transition: all 0.4s; }
blockquote {
  margin: 40px 0;
  background: $pink-blockquote;
  border-left: 2px solid $purple;
  padding: 30px;
  @include open-sans-italic;
  color: $purple2;
  cite {
    display: block;
    @include fontsize-rem(14px);
    @include lato-bold;
    text-transform: uppercase;
    margin-top: 10px;
    font-style: normal;
    color: $grey-6;
    letter-spacing: 2px; } }
ul {
  &.social-links {
    li {
      display: inline-block;
      margin-right: 0.5rem;
      @include fontsize-rem(22px);
      .icon {
        &.-facebook {
          color: $fb-color; }
        &.-twitter {
          color: $tw-color; }
        &.-instagram {
          color: $in-color; }
        &.-google {
          color: $gp-color; }
        &.-vime {
          color: $vm-color; } } } } }

.page-bg {
  background-size: cover;
  min-height: 250px;
  background: no-repeat fixed;
  @include breakpoint($phone) {
    min-height: 130px; }
  @include breakpoint($tablet-portrait) {
    min-height: 130px; }
  .wrapper {
    height: 100%;
    padding-top: 100px;
    color: white;
    text-align: center;
    @include breakpoint($phone) {
      padding-top: 40px; }
    @include breakpoint($tablet-portrait) {
      padding-top: 40px; }
    h2 {
      color: white;
      margin-top: 15px;
      @include fontsize-rem(30px);
      @include breakpoint($phone) {
        @include fontsize-rem(24px); }
      @include breakpoint($tablet-portrait) {
        @include fontsize-rem(24px); } } } }
