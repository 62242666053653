/* -------------------------
  Typography rules
  --------------------------- */
@mixin lato-regular {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; }
@mixin lato-bold {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700; }
@mixin lato-ultra {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900; }

@mixin open-sans-regular {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; }
@mixin open-sans-italic {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: italic; }


h1, h2, h3, h4, h5, h6 {
  @include lato-bold;
  color: $grey-6;
  text-transform: uppercase;
  letter-spacing: 4px; }
h1 {
  @include fontsize-rem(40px); }
h2 {
  @include fontsize-rem(22px);
  @include breakpoint($phone) {
    @include fontsize-rem(18px); } }
h3 {
  @include fontsize-rem(18px);
  @include breakpoint($phone) {
    @include fontsize-rem(15px); } }
h4 {
  @include fontsize-rem(16px); }
h5 {
  @include fontsize-rem(14px);
  @include lato-regular; }
h6 {
  @include fontsize-rem(12px);
  @include lato-ultra; }
p span.text-lg {
  @include fontsize-rem(18px); }
strong {
  @include lato-bold; }
