/* -------------------------
  About specific styles
  --------------------------- */
.about {
  color: $grey-6;
  article {
    margin-top: 4rem; }
  .content {
    p {
      @include fontsize-rem(14px);
      line-height: 1.5rem;
      strong {
        @include lato-bold;
        @include fontsize-rem(16px);
        color: $grey-3; } } }
  .moto {
    background-color: $grey-f7;
    padding: 3rem;
    @include breakpoint($phone) {
      margin-top: 40px; }
    h3 {
      margin-top: 0rem;
      @include fontsize-rem(20px);
      text-transform: inherit;
      @include open-sans-regular;
      letter-spacing: 2px;
      line-height: 1.5rem; }
    h6 {
      margin-top: 1rem;
      @include lato-regular;
      @include fontsize-rem(10px);
      letter-spacing: 1px; } }
  .team-side {
    div[class^='col-'] {
      @include breakpoint($phone) {
        margin: 30px 0;
        &:first-child {
          margin-top: 0; } }
      @include breakpoint($tablet-portrait) {
        margin: 30px 0;
        &:first-child {
          margin-top: 0; } } }
    header {
      margin-bottom: 2.5rem;
      p {
        @include fontsize-rem(14px); } }
    p {
      @include fontsize-rem(16px); }
    img {
      width: 100%;
      margin-bottom: 1rem;
      @include breakpoint($phone) {
        width: 50%; }
      @include breakpoint($tablet-portrait) {
        width: 50%; } } } }

