/* -------------------------
  Elements specific styles
  --------------------------- */
.grid-block {
  height: 20px;
  background-color: $grey-c;
  margin-bottom: 1rem; }
.list-of-icons {
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    line-height: 5rem;
    vertical-align: middle;
    text-align: center;
    &:hover {
      cursor: pointer;
      padding: 0;
      i.icon {
        margin-top: -1rem;
        font-size: 1.8rem; } }
    i.icon {
      vertical-align: middle;
      @include transition(all 0.35s);
      font-size: 1rem; } } }
