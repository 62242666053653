/* -------------------------
  Variables
  --------------------------- */

// Phones breakpoint
$phone: max-width 767px;

// Tablet breakpoint
$tablet: 768px 1024px;

// Tablet in portrait mode breakpoint
$tablet-portrait: (min-device-width 768px) (max-device-width 1024px) (orientation portrait);

// Tablet in landscape mode breakpoint
$tablet-landscape: (min-device-width 768px) (max-device-width 1024px) (orientation landscape);

// Small laptops breakpoint
$laptop: 1024px 1300px;

// Large desktops breakpoint
$desktop: 1300px;
