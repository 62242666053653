// // DO NOT EDIT
////Generated by gulpfile.js/tasks/iconFont.js
////from
@font-face {
  font-family: icons;
  src: url("../fonts//icons.eot");
  src: url("../fonts//icons.eot?#iefix") format('embedded-opentype'), url("../fonts//icons.woff") format('woff'), url("../fonts//icons.ttf") format('truetype'), url("../fonts//icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content; } }

.icon {
  @include icon; }

// Save variable
$icon-magnifying-glass: "\EA01";
// Save mixin
@mixin icon--magnifying-glass {
  @include icon($icon-magnifying-glass) {
    @content; } }
// Expose as class
.icon.-magnifying-glass:before {
  content: $icon-magnifying-glass; }

// Save variable
$icon-add-to-list: "\EA03";
// Save mixin
@mixin icon--add-to-list {
  @include icon($icon-add-to-list) {
    @content; } }
// Expose as class
.icon.-add-to-list:before {
  content: $icon-add-to-list; }

// Save variable
$icon-add-user: "\EA04";
// Save mixin
@mixin icon--add-user {
  @include icon($icon-add-user) {
    @content; } }
// Expose as class
.icon.-add-user:before {
  content: $icon-add-user; }

// Save variable
$icon-address: "\EA05";
// Save mixin
@mixin icon--address {
  @include icon($icon-address) {
    @content; } }
// Expose as class
.icon.-address:before {
  content: $icon-address; }

// Save variable
$icon-adjust: "\EA06";
// Save mixin
@mixin icon--adjust {
  @include icon($icon-adjust) {
    @content; } }
// Expose as class
.icon.-adjust:before {
  content: $icon-adjust; }

// Save variable
$icon-air: "\EA07";
// Save mixin
@mixin icon--air {
  @include icon($icon-air) {
    @content; } }
// Expose as class
.icon.-air:before {
  content: $icon-air; }

// Save variable
$icon-aircraft-landing: "\EA08";
// Save mixin
@mixin icon--aircraft-landing {
  @include icon($icon-aircraft-landing) {
    @content; } }
// Expose as class
.icon.-aircraft-landing:before {
  content: $icon-aircraft-landing; }

// Save variable
$icon-aircraft-take-off: "\EA09";
// Save mixin
@mixin icon--aircraft-take-off {
  @include icon($icon-aircraft-take-off) {
    @content; } }
// Expose as class
.icon.-aircraft-take-off:before {
  content: $icon-aircraft-take-off; }

// Save variable
$icon-aircraft: "\EA0A";
// Save mixin
@mixin icon--aircraft {
  @include icon($icon-aircraft) {
    @content; } }
// Expose as class
.icon.-aircraft:before {
  content: $icon-aircraft; }

// Save variable
$icon-align-bottom: "\EA0B";
// Save mixin
@mixin icon--align-bottom {
  @include icon($icon-align-bottom) {
    @content; } }
// Expose as class
.icon.-align-bottom:before {
  content: $icon-align-bottom; }

// Save variable
$icon-align-horizontal-middle: "\EA0C";
// Save mixin
@mixin icon--align-horizontal-middle {
  @include icon($icon-align-horizontal-middle) {
    @content; } }
// Expose as class
.icon.-align-horizontal-middle:before {
  content: $icon-align-horizontal-middle; }

// Save variable
$icon-align-left: "\EA0D";
// Save mixin
@mixin icon--align-left {
  @include icon($icon-align-left) {
    @content; } }
// Expose as class
.icon.-align-left:before {
  content: $icon-align-left; }

// Save variable
$icon-align-right: "\EA0E";
// Save mixin
@mixin icon--align-right {
  @include icon($icon-align-right) {
    @content; } }
// Expose as class
.icon.-align-right:before {
  content: $icon-align-right; }

// Save variable
$icon-align-top: "\EA0F";
// Save mixin
@mixin icon--align-top {
  @include icon($icon-align-top) {
    @content; } }
// Expose as class
.icon.-align-top:before {
  content: $icon-align-top; }

// Save variable
$icon-align-vertical-middle: "\EA10";
// Save mixin
@mixin icon--align-vertical-middle {
  @include icon($icon-align-vertical-middle) {
    @content; } }
// Expose as class
.icon.-align-vertical-middle:before {
  content: $icon-align-vertical-middle; }

// Save variable
$icon-app-store: "\EA11";
// Save mixin
@mixin icon--app-store {
  @include icon($icon-app-store) {
    @content; } }
// Expose as class
.icon.-app-store:before {
  content: $icon-app-store; }

// Save variable
$icon-archive: "\EA12";
// Save mixin
@mixin icon--archive {
  @include icon($icon-archive) {
    @content; } }
// Expose as class
.icon.-archive:before {
  content: $icon-archive; }

// Save variable
$icon-area-graph: "\EA13";
// Save mixin
@mixin icon--area-graph {
  @include icon($icon-area-graph) {
    @content; } }
// Expose as class
.icon.-area-graph:before {
  content: $icon-area-graph; }

// Save variable
$icon-arrow-bold-down: "\EA14";
// Save mixin
@mixin icon--arrow-bold-down {
  @include icon($icon-arrow-bold-down) {
    @content; } }
// Expose as class
.icon.-arrow-bold-down:before {
  content: $icon-arrow-bold-down; }

// Save variable
$icon-arrow-bold-left: "\EA15";
// Save mixin
@mixin icon--arrow-bold-left {
  @include icon($icon-arrow-bold-left) {
    @content; } }
// Expose as class
.icon.-arrow-bold-left:before {
  content: $icon-arrow-bold-left; }

// Save variable
$icon-arrow-bold-right: "\EA16";
// Save mixin
@mixin icon--arrow-bold-right {
  @include icon($icon-arrow-bold-right) {
    @content; } }
// Expose as class
.icon.-arrow-bold-right:before {
  content: $icon-arrow-bold-right; }

// Save variable
$icon-arrow-bold-up: "\EA17";
// Save mixin
@mixin icon--arrow-bold-up {
  @include icon($icon-arrow-bold-up) {
    @content; } }
// Expose as class
.icon.-arrow-bold-up:before {
  content: $icon-arrow-bold-up; }

// Save variable
$icon-arrow-down: "\EA18";
// Save mixin
@mixin icon--arrow-down {
  @include icon($icon-arrow-down) {
    @content; } }
// Expose as class
.icon.-arrow-down:before {
  content: $icon-arrow-down; }

// Save variable
$icon-arrow-left: "\EA19";
// Save mixin
@mixin icon--arrow-left {
  @include icon($icon-arrow-left) {
    @content; } }
// Expose as class
.icon.-arrow-left:before {
  content: $icon-arrow-left; }

// Save variable
$icon-arrow-long-down: "\EA1A";
// Save mixin
@mixin icon--arrow-long-down {
  @include icon($icon-arrow-long-down) {
    @content; } }
// Expose as class
.icon.-arrow-long-down:before {
  content: $icon-arrow-long-down; }

// Save variable
$icon-arrow-long-left: "\EA1B";
// Save mixin
@mixin icon--arrow-long-left {
  @include icon($icon-arrow-long-left) {
    @content; } }
// Expose as class
.icon.-arrow-long-left:before {
  content: $icon-arrow-long-left; }

// Save variable
$icon-arrow-long-right: "\EA1C";
// Save mixin
@mixin icon--arrow-long-right {
  @include icon($icon-arrow-long-right) {
    @content; } }
// Expose as class
.icon.-arrow-long-right:before {
  content: $icon-arrow-long-right; }

// Save variable
$icon-arrow-long-up: "\EA1D";
// Save mixin
@mixin icon--arrow-long-up {
  @include icon($icon-arrow-long-up) {
    @content; } }
// Expose as class
.icon.-arrow-long-up:before {
  content: $icon-arrow-long-up; }

// Save variable
$icon-arrow-right: "\EA1E";
// Save mixin
@mixin icon--arrow-right {
  @include icon($icon-arrow-right) {
    @content; } }
// Expose as class
.icon.-arrow-right:before {
  content: $icon-arrow-right; }

// Save variable
$icon-arrow-up: "\EA1F";
// Save mixin
@mixin icon--arrow-up {
  @include icon($icon-arrow-up) {
    @content; } }
// Expose as class
.icon.-arrow-up:before {
  content: $icon-arrow-up; }

// Save variable
$icon-arrow-with-circle-down: "\EA20";
// Save mixin
@mixin icon--arrow-with-circle-down {
  @include icon($icon-arrow-with-circle-down) {
    @content; } }
// Expose as class
.icon.-arrow-with-circle-down:before {
  content: $icon-arrow-with-circle-down; }

// Save variable
$icon-arrow-with-circle-left: "\EA21";
// Save mixin
@mixin icon--arrow-with-circle-left {
  @include icon($icon-arrow-with-circle-left) {
    @content; } }
// Expose as class
.icon.-arrow-with-circle-left:before {
  content: $icon-arrow-with-circle-left; }

// Save variable
$icon-arrow-with-circle-right: "\EA22";
// Save mixin
@mixin icon--arrow-with-circle-right {
  @include icon($icon-arrow-with-circle-right) {
    @content; } }
// Expose as class
.icon.-arrow-with-circle-right:before {
  content: $icon-arrow-with-circle-right; }

// Save variable
$icon-arrow-with-circle-up: "\EA23";
// Save mixin
@mixin icon--arrow-with-circle-up {
  @include icon($icon-arrow-with-circle-up) {
    @content; } }
// Expose as class
.icon.-arrow-with-circle-up:before {
  content: $icon-arrow-with-circle-up; }

// Save variable
$icon-attachment: "\EA24";
// Save mixin
@mixin icon--attachment {
  @include icon($icon-attachment) {
    @content; } }
// Expose as class
.icon.-attachment:before {
  content: $icon-attachment; }

// Save variable
$icon-awareness-ribbon: "\EA25";
// Save mixin
@mixin icon--awareness-ribbon {
  @include icon($icon-awareness-ribbon) {
    @content; } }
// Expose as class
.icon.-awareness-ribbon:before {
  content: $icon-awareness-ribbon; }

// Save variable
$icon-back-in-time: "\EA26";
// Save mixin
@mixin icon--back-in-time {
  @include icon($icon-back-in-time) {
    @content; } }
// Expose as class
.icon.-back-in-time:before {
  content: $icon-back-in-time; }

// Save variable
$icon-back: "\EA27";
// Save mixin
@mixin icon--back {
  @include icon($icon-back) {
    @content; } }
// Expose as class
.icon.-back:before {
  content: $icon-back; }

// Save variable
$icon-baidu: "\EA28";
// Save mixin
@mixin icon--baidu {
  @include icon($icon-baidu) {
    @content; } }
// Expose as class
.icon.-baidu:before {
  content: $icon-baidu; }

// Save variable
$icon-bar-graph: "\EA29";
// Save mixin
@mixin icon--bar-graph {
  @include icon($icon-bar-graph) {
    @content; } }
// Expose as class
.icon.-bar-graph:before {
  content: $icon-bar-graph; }

// Save variable
$icon-basecamp: "\EA2A";
// Save mixin
@mixin icon--basecamp {
  @include icon($icon-basecamp) {
    @content; } }
// Expose as class
.icon.-basecamp:before {
  content: $icon-basecamp; }

// Save variable
$icon-battery: "\EA2B";
// Save mixin
@mixin icon--battery {
  @include icon($icon-battery) {
    @content; } }
// Expose as class
.icon.-battery:before {
  content: $icon-battery; }

// Save variable
$icon-beamed-note: "\EA2C";
// Save mixin
@mixin icon--beamed-note {
  @include icon($icon-beamed-note) {
    @content; } }
// Expose as class
.icon.-beamed-note:before {
  content: $icon-beamed-note; }

// Save variable
$icon-behance: "\EA2D";
// Save mixin
@mixin icon--behance {
  @include icon($icon-behance) {
    @content; } }
// Expose as class
.icon.-behance:before {
  content: $icon-behance; }

// Save variable
$icon-bell: "\EA2E";
// Save mixin
@mixin icon--bell {
  @include icon($icon-bell) {
    @content; } }
// Expose as class
.icon.-bell:before {
  content: $icon-bell; }

// Save variable
$icon-blackboard: "\EA2F";
// Save mixin
@mixin icon--blackboard {
  @include icon($icon-blackboard) {
    @content; } }
// Expose as class
.icon.-blackboard:before {
  content: $icon-blackboard; }

// Save variable
$icon-block: "\EA30";
// Save mixin
@mixin icon--block {
  @include icon($icon-block) {
    @content; } }
// Expose as class
.icon.-block:before {
  content: $icon-block; }

// Save variable
$icon-book: "\EA31";
// Save mixin
@mixin icon--book {
  @include icon($icon-book) {
    @content; } }
// Expose as class
.icon.-book:before {
  content: $icon-book; }

// Save variable
$icon-bookmark: "\EA32";
// Save mixin
@mixin icon--bookmark {
  @include icon($icon-bookmark) {
    @content; } }
// Expose as class
.icon.-bookmark:before {
  content: $icon-bookmark; }

// Save variable
$icon-bookmarks: "\EA33";
// Save mixin
@mixin icon--bookmarks {
  @include icon($icon-bookmarks) {
    @content; } }
// Expose as class
.icon.-bookmarks:before {
  content: $icon-bookmarks; }

// Save variable
$icon-bowl: "\EA34";
// Save mixin
@mixin icon--bowl {
  @include icon($icon-bowl) {
    @content; } }
// Expose as class
.icon.-bowl:before {
  content: $icon-bowl; }

// Save variable
$icon-box: "\EA35";
// Save mixin
@mixin icon--box {
  @include icon($icon-box) {
    @content; } }
// Expose as class
.icon.-box:before {
  content: $icon-box; }

// Save variable
$icon-briefcase: "\EA36";
// Save mixin
@mixin icon--briefcase {
  @include icon($icon-briefcase) {
    @content; } }
// Expose as class
.icon.-briefcase:before {
  content: $icon-briefcase; }

// Save variable
$icon-browser: "\EA37";
// Save mixin
@mixin icon--browser {
  @include icon($icon-browser) {
    @content; } }
// Expose as class
.icon.-browser:before {
  content: $icon-browser; }

// Save variable
$icon-brush: "\EA38";
// Save mixin
@mixin icon--brush {
  @include icon($icon-brush) {
    @content; } }
// Expose as class
.icon.-brush:before {
  content: $icon-brush; }

// Save variable
$icon-bucket: "\EA39";
// Save mixin
@mixin icon--bucket {
  @include icon($icon-bucket) {
    @content; } }
// Expose as class
.icon.-bucket:before {
  content: $icon-bucket; }

// Save variable
$icon-bug: "\EA3A";
// Save mixin
@mixin icon--bug {
  @include icon($icon-bug) {
    @content; } }
// Expose as class
.icon.-bug:before {
  content: $icon-bug; }

// Save variable
$icon-cake: "\EA3B";
// Save mixin
@mixin icon--cake {
  @include icon($icon-cake) {
    @content; } }
// Expose as class
.icon.-cake:before {
  content: $icon-cake; }

// Save variable
$icon-calculator: "\EA3C";
// Save mixin
@mixin icon--calculator {
  @include icon($icon-calculator) {
    @content; } }
// Expose as class
.icon.-calculator:before {
  content: $icon-calculator; }

// Save variable
$icon-calendar: "\EA3D";
// Save mixin
@mixin icon--calendar {
  @include icon($icon-calendar) {
    @content; } }
// Expose as class
.icon.-calendar:before {
  content: $icon-calendar; }

// Save variable
$icon-camera: "\EA3E";
// Save mixin
@mixin icon--camera {
  @include icon($icon-camera) {
    @content; } }
// Expose as class
.icon.-camera:before {
  content: $icon-camera; }

// Save variable
$icon-ccw: "\EA3F";
// Save mixin
@mixin icon--ccw {
  @include icon($icon-ccw) {
    @content; } }
// Expose as class
.icon.-ccw:before {
  content: $icon-ccw; }

// Save variable
$icon-chat: "\EA40";
// Save mixin
@mixin icon--chat {
  @include icon($icon-chat) {
    @content; } }
// Expose as class
.icon.-chat:before {
  content: $icon-chat; }

// Save variable
$icon-check: "\EA41";
// Save mixin
@mixin icon--check {
  @include icon($icon-check) {
    @content; } }
// Expose as class
.icon.-check:before {
  content: $icon-check; }

// Save variable
$icon-chevron-down: "\EA42";
// Save mixin
@mixin icon--chevron-down {
  @include icon($icon-chevron-down) {
    @content; } }
// Expose as class
.icon.-chevron-down:before {
  content: $icon-chevron-down; }

// Save variable
$icon-chevron-left: "\EA43";
// Save mixin
@mixin icon--chevron-left {
  @include icon($icon-chevron-left) {
    @content; } }
// Expose as class
.icon.-chevron-left:before {
  content: $icon-chevron-left; }

// Save variable
$icon-chevron-right: "\EA44";
// Save mixin
@mixin icon--chevron-right {
  @include icon($icon-chevron-right) {
    @content; } }
// Expose as class
.icon.-chevron-right:before {
  content: $icon-chevron-right; }

// Save variable
$icon-chevron-small-down: "\EA45";
// Save mixin
@mixin icon--chevron-small-down {
  @include icon($icon-chevron-small-down) {
    @content; } }
// Expose as class
.icon.-chevron-small-down:before {
  content: $icon-chevron-small-down; }

// Save variable
$icon-chevron-small-left: "\EA46";
// Save mixin
@mixin icon--chevron-small-left {
  @include icon($icon-chevron-small-left) {
    @content; } }
// Expose as class
.icon.-chevron-small-left:before {
  content: $icon-chevron-small-left; }

// Save variable
$icon-chevron-small-right: "\EA47";
// Save mixin
@mixin icon--chevron-small-right {
  @include icon($icon-chevron-small-right) {
    @content; } }
// Expose as class
.icon.-chevron-small-right:before {
  content: $icon-chevron-small-right; }

// Save variable
$icon-chevron-small-up: "\EA48";
// Save mixin
@mixin icon--chevron-small-up {
  @include icon($icon-chevron-small-up) {
    @content; } }
// Expose as class
.icon.-chevron-small-up:before {
  content: $icon-chevron-small-up; }

// Save variable
$icon-chevron-thin-down: "\EA49";
// Save mixin
@mixin icon--chevron-thin-down {
  @include icon($icon-chevron-thin-down) {
    @content; } }
// Expose as class
.icon.-chevron-thin-down:before {
  content: $icon-chevron-thin-down; }

// Save variable
$icon-chevron-thin-left: "\EA4A";
// Save mixin
@mixin icon--chevron-thin-left {
  @include icon($icon-chevron-thin-left) {
    @content; } }
// Expose as class
.icon.-chevron-thin-left:before {
  content: $icon-chevron-thin-left; }

// Save variable
$icon-chevron-thin-right: "\EA4B";
// Save mixin
@mixin icon--chevron-thin-right {
  @include icon($icon-chevron-thin-right) {
    @content; } }
// Expose as class
.icon.-chevron-thin-right:before {
  content: $icon-chevron-thin-right; }

// Save variable
$icon-chevron-thin-up: "\EA4C";
// Save mixin
@mixin icon--chevron-thin-up {
  @include icon($icon-chevron-thin-up) {
    @content; } }
// Expose as class
.icon.-chevron-thin-up:before {
  content: $icon-chevron-thin-up; }

// Save variable
$icon-chevron-up: "\EA4D";
// Save mixin
@mixin icon--chevron-up {
  @include icon($icon-chevron-up) {
    @content; } }
// Expose as class
.icon.-chevron-up:before {
  content: $icon-chevron-up; }

// Save variable
$icon-chevron-with-circle-down: "\EA4E";
// Save mixin
@mixin icon--chevron-with-circle-down {
  @include icon($icon-chevron-with-circle-down) {
    @content; } }
// Expose as class
.icon.-chevron-with-circle-down:before {
  content: $icon-chevron-with-circle-down; }

// Save variable
$icon-chevron-with-circle-left: "\EA4F";
// Save mixin
@mixin icon--chevron-with-circle-left {
  @include icon($icon-chevron-with-circle-left) {
    @content; } }
// Expose as class
.icon.-chevron-with-circle-left:before {
  content: $icon-chevron-with-circle-left; }

// Save variable
$icon-chevron-with-circle-right: "\EA50";
// Save mixin
@mixin icon--chevron-with-circle-right {
  @include icon($icon-chevron-with-circle-right) {
    @content; } }
// Expose as class
.icon.-chevron-with-circle-right:before {
  content: $icon-chevron-with-circle-right; }

// Save variable
$icon-chevron-with-circle-up: "\EA51";
// Save mixin
@mixin icon--chevron-with-circle-up {
  @include icon($icon-chevron-with-circle-up) {
    @content; } }
// Expose as class
.icon.-chevron-with-circle-up:before {
  content: $icon-chevron-with-circle-up; }

// Save variable
$icon-circle-with-cross: "\EA52";
// Save mixin
@mixin icon--circle-with-cross {
  @include icon($icon-circle-with-cross) {
    @content; } }
// Expose as class
.icon.-circle-with-cross:before {
  content: $icon-circle-with-cross; }

// Save variable
$icon-circle-with-minus: "\EA53";
// Save mixin
@mixin icon--circle-with-minus {
  @include icon($icon-circle-with-minus) {
    @content; } }
// Expose as class
.icon.-circle-with-minus:before {
  content: $icon-circle-with-minus; }

// Save variable
$icon-circle-with-plus: "\EA54";
// Save mixin
@mixin icon--circle-with-plus {
  @include icon($icon-circle-with-plus) {
    @content; } }
// Expose as class
.icon.-circle-with-plus:before {
  content: $icon-circle-with-plus; }

// Save variable
$icon-circle: "\EA55";
// Save mixin
@mixin icon--circle {
  @include icon($icon-circle) {
    @content; } }
// Expose as class
.icon.-circle:before {
  content: $icon-circle; }

// Save variable
$icon-circular-graph: "\EA56";
// Save mixin
@mixin icon--circular-graph {
  @include icon($icon-circular-graph) {
    @content; } }
// Expose as class
.icon.-circular-graph:before {
  content: $icon-circular-graph; }

// Save variable
$icon-clapperboard: "\EA57";
// Save mixin
@mixin icon--clapperboard {
  @include icon($icon-clapperboard) {
    @content; } }
// Expose as class
.icon.-clapperboard:before {
  content: $icon-clapperboard; }

// Save variable
$icon-classic-computer: "\EA58";
// Save mixin
@mixin icon--classic-computer {
  @include icon($icon-classic-computer) {
    @content; } }
// Expose as class
.icon.-classic-computer:before {
  content: $icon-classic-computer; }

// Save variable
$icon-clipboard: "\EA59";
// Save mixin
@mixin icon--clipboard {
  @include icon($icon-clipboard) {
    @content; } }
// Expose as class
.icon.-clipboard:before {
  content: $icon-clipboard; }

// Save variable
$icon-clock: "\EA5A";
// Save mixin
@mixin icon--clock {
  @include icon($icon-clock) {
    @content; } }
// Expose as class
.icon.-clock:before {
  content: $icon-clock; }

// Save variable
$icon-cloud: "\EA5B";
// Save mixin
@mixin icon--cloud {
  @include icon($icon-cloud) {
    @content; } }
// Expose as class
.icon.-cloud:before {
  content: $icon-cloud; }

// Save variable
$icon-code: "\EA5C";
// Save mixin
@mixin icon--code {
  @include icon($icon-code) {
    @content; } }
// Expose as class
.icon.-code:before {
  content: $icon-code; }

// Save variable
$icon-cog: "\EA5D";
// Save mixin
@mixin icon--cog {
  @include icon($icon-cog) {
    @content; } }
// Expose as class
.icon.-cog:before {
  content: $icon-cog; }

// Save variable
$icon-colours: "\EA5E";
// Save mixin
@mixin icon--colours {
  @include icon($icon-colours) {
    @content; } }
// Expose as class
.icon.-colours:before {
  content: $icon-colours; }

// Save variable
$icon-compass: "\EA5F";
// Save mixin
@mixin icon--compass {
  @include icon($icon-compass) {
    @content; } }
// Expose as class
.icon.-compass:before {
  content: $icon-compass; }

// Save variable
$icon-controller-fast-backward: "\EA60";
// Save mixin
@mixin icon--controller-fast-backward {
  @include icon($icon-controller-fast-backward) {
    @content; } }
// Expose as class
.icon.-controller-fast-backward:before {
  content: $icon-controller-fast-backward; }

// Save variable
$icon-controller-fast-forward: "\EA61";
// Save mixin
@mixin icon--controller-fast-forward {
  @include icon($icon-controller-fast-forward) {
    @content; } }
// Expose as class
.icon.-controller-fast-forward:before {
  content: $icon-controller-fast-forward; }

// Save variable
$icon-controller-jump-to-start: "\EA62";
// Save mixin
@mixin icon--controller-jump-to-start {
  @include icon($icon-controller-jump-to-start) {
    @content; } }
// Expose as class
.icon.-controller-jump-to-start:before {
  content: $icon-controller-jump-to-start; }

// Save variable
$icon-controller-next: "\EA63";
// Save mixin
@mixin icon--controller-next {
  @include icon($icon-controller-next) {
    @content; } }
// Expose as class
.icon.-controller-next:before {
  content: $icon-controller-next; }

// Save variable
$icon-controller-paus: "\EA64";
// Save mixin
@mixin icon--controller-paus {
  @include icon($icon-controller-paus) {
    @content; } }
// Expose as class
.icon.-controller-paus:before {
  content: $icon-controller-paus; }

// Save variable
$icon-controller-play: "\EA65";
// Save mixin
@mixin icon--controller-play {
  @include icon($icon-controller-play) {
    @content; } }
// Expose as class
.icon.-controller-play:before {
  content: $icon-controller-play; }

// Save variable
$icon-controller-record: "\EA66";
// Save mixin
@mixin icon--controller-record {
  @include icon($icon-controller-record) {
    @content; } }
// Expose as class
.icon.-controller-record:before {
  content: $icon-controller-record; }

// Save variable
$icon-controller-stop: "\EA67";
// Save mixin
@mixin icon--controller-stop {
  @include icon($icon-controller-stop) {
    @content; } }
// Expose as class
.icon.-controller-stop:before {
  content: $icon-controller-stop; }

// Save variable
$icon-controller-volume: "\EA68";
// Save mixin
@mixin icon--controller-volume {
  @include icon($icon-controller-volume) {
    @content; } }
// Expose as class
.icon.-controller-volume:before {
  content: $icon-controller-volume; }

// Save variable
$icon-copy: "\EA69";
// Save mixin
@mixin icon--copy {
  @include icon($icon-copy) {
    @content; } }
// Expose as class
.icon.-copy:before {
  content: $icon-copy; }

// Save variable
$icon-creative-cloud: "\EA6A";
// Save mixin
@mixin icon--creative-cloud {
  @include icon($icon-creative-cloud) {
    @content; } }
// Expose as class
.icon.-creative-cloud:before {
  content: $icon-creative-cloud; }

// Save variable
$icon-creative-commons-attribution: "\EA6B";
// Save mixin
@mixin icon--creative-commons-attribution {
  @include icon($icon-creative-commons-attribution) {
    @content; } }
// Expose as class
.icon.-creative-commons-attribution:before {
  content: $icon-creative-commons-attribution; }

// Save variable
$icon-creative-commons-noderivs: "\EA6C";
// Save mixin
@mixin icon--creative-commons-noderivs {
  @include icon($icon-creative-commons-noderivs) {
    @content; } }
// Expose as class
.icon.-creative-commons-noderivs:before {
  content: $icon-creative-commons-noderivs; }

// Save variable
$icon-creative-commons-noncommercial-eu: "\EA6D";
// Save mixin
@mixin icon--creative-commons-noncommercial-eu {
  @include icon($icon-creative-commons-noncommercial-eu) {
    @content; } }
// Expose as class
.icon.-creative-commons-noncommercial-eu:before {
  content: $icon-creative-commons-noncommercial-eu; }

// Save variable
$icon-creative-commons-noncommercial-us: "\EA6E";
// Save mixin
@mixin icon--creative-commons-noncommercial-us {
  @include icon($icon-creative-commons-noncommercial-us) {
    @content; } }
// Expose as class
.icon.-creative-commons-noncommercial-us:before {
  content: $icon-creative-commons-noncommercial-us; }

// Save variable
$icon-creative-commons-public-domain: "\EA6F";
// Save mixin
@mixin icon--creative-commons-public-domain {
  @include icon($icon-creative-commons-public-domain) {
    @content; } }
// Expose as class
.icon.-creative-commons-public-domain:before {
  content: $icon-creative-commons-public-domain; }

// Save variable
$icon-creative-commons-remix: "\EA70";
// Save mixin
@mixin icon--creative-commons-remix {
  @include icon($icon-creative-commons-remix) {
    @content; } }
// Expose as class
.icon.-creative-commons-remix:before {
  content: $icon-creative-commons-remix; }

// Save variable
$icon-creative-commons-share: "\EA71";
// Save mixin
@mixin icon--creative-commons-share {
  @include icon($icon-creative-commons-share) {
    @content; } }
// Expose as class
.icon.-creative-commons-share:before {
  content: $icon-creative-commons-share; }

// Save variable
$icon-creative-commons-sharealike: "\EA72";
// Save mixin
@mixin icon--creative-commons-sharealike {
  @include icon($icon-creative-commons-sharealike) {
    @content; } }
// Expose as class
.icon.-creative-commons-sharealike:before {
  content: $icon-creative-commons-sharealike; }

// Save variable
$icon-creative-commons: "\EA73";
// Save mixin
@mixin icon--creative-commons {
  @include icon($icon-creative-commons) {
    @content; } }
// Expose as class
.icon.-creative-commons:before {
  content: $icon-creative-commons; }

// Save variable
$icon-credit-card: "\EA74";
// Save mixin
@mixin icon--credit-card {
  @include icon($icon-credit-card) {
    @content; } }
// Expose as class
.icon.-credit-card:before {
  content: $icon-credit-card; }

// Save variable
$icon-credit: "\EA75";
// Save mixin
@mixin icon--credit {
  @include icon($icon-credit) {
    @content; } }
// Expose as class
.icon.-credit:before {
  content: $icon-credit; }

// Save variable
$icon-crop: "\EA76";
// Save mixin
@mixin icon--crop {
  @include icon($icon-crop) {
    @content; } }
// Expose as class
.icon.-crop:before {
  content: $icon-crop; }

// Save variable
$icon-cross: "\EA77";
// Save mixin
@mixin icon--cross {
  @include icon($icon-cross) {
    @content; } }
// Expose as class
.icon.-cross:before {
  content: $icon-cross; }

// Save variable
$icon-cup: "\EA78";
// Save mixin
@mixin icon--cup {
  @include icon($icon-cup) {
    @content; } }
// Expose as class
.icon.-cup:before {
  content: $icon-cup; }

// Save variable
$icon-cw: "\EA79";
// Save mixin
@mixin icon--cw {
  @include icon($icon-cw) {
    @content; } }
// Expose as class
.icon.-cw:before {
  content: $icon-cw; }

// Save variable
$icon-cycle: "\EA7A";
// Save mixin
@mixin icon--cycle {
  @include icon($icon-cycle) {
    @content; } }
// Expose as class
.icon.-cycle:before {
  content: $icon-cycle; }

// Save variable
$icon-database: "\EA7B";
// Save mixin
@mixin icon--database {
  @include icon($icon-database) {
    @content; } }
// Expose as class
.icon.-database:before {
  content: $icon-database; }

// Save variable
$icon-dial-pad: "\EA7C";
// Save mixin
@mixin icon--dial-pad {
  @include icon($icon-dial-pad) {
    @content; } }
// Expose as class
.icon.-dial-pad:before {
  content: $icon-dial-pad; }

// Save variable
$icon-direction: "\EA7D";
// Save mixin
@mixin icon--direction {
  @include icon($icon-direction) {
    @content; } }
// Expose as class
.icon.-direction:before {
  content: $icon-direction; }

// Save variable
$icon-document-landscape: "\EA7E";
// Save mixin
@mixin icon--document-landscape {
  @include icon($icon-document-landscape) {
    @content; } }
// Expose as class
.icon.-document-landscape:before {
  content: $icon-document-landscape; }

// Save variable
$icon-document: "\EA7F";
// Save mixin
@mixin icon--document {
  @include icon($icon-document) {
    @content; } }
// Expose as class
.icon.-document:before {
  content: $icon-document; }

// Save variable
$icon-documents: "\EA80";
// Save mixin
@mixin icon--documents {
  @include icon($icon-documents) {
    @content; } }
// Expose as class
.icon.-documents:before {
  content: $icon-documents; }

// Save variable
$icon-dot-single: "\EA81";
// Save mixin
@mixin icon--dot-single {
  @include icon($icon-dot-single) {
    @content; } }
// Expose as class
.icon.-dot-single:before {
  content: $icon-dot-single; }

// Save variable
$icon-dots-three-horizontal: "\EA82";
// Save mixin
@mixin icon--dots-three-horizontal {
  @include icon($icon-dots-three-horizontal) {
    @content; } }
// Expose as class
.icon.-dots-three-horizontal:before {
  content: $icon-dots-three-horizontal; }

// Save variable
$icon-dots-three-vertical: "\EA83";
// Save mixin
@mixin icon--dots-three-vertical {
  @include icon($icon-dots-three-vertical) {
    @content; } }
// Expose as class
.icon.-dots-three-vertical:before {
  content: $icon-dots-three-vertical; }

// Save variable
$icon-dots-two-horizontal: "\EA84";
// Save mixin
@mixin icon--dots-two-horizontal {
  @include icon($icon-dots-two-horizontal) {
    @content; } }
// Expose as class
.icon.-dots-two-horizontal:before {
  content: $icon-dots-two-horizontal; }

// Save variable
$icon-dots-two-vertical: "\EA85";
// Save mixin
@mixin icon--dots-two-vertical {
  @include icon($icon-dots-two-vertical) {
    @content; } }
// Expose as class
.icon.-dots-two-vertical:before {
  content: $icon-dots-two-vertical; }

// Save variable
$icon-download: "\EA86";
// Save mixin
@mixin icon--download {
  @include icon($icon-download) {
    @content; } }
// Expose as class
.icon.-download:before {
  content: $icon-download; }

// Save variable
$icon-dribbble-with-circle: "\EA87";
// Save mixin
@mixin icon--dribbble-with-circle {
  @include icon($icon-dribbble-with-circle) {
    @content; } }
// Expose as class
.icon.-dribbble-with-circle:before {
  content: $icon-dribbble-with-circle; }

// Save variable
$icon-dribbble: "\EA88";
// Save mixin
@mixin icon--dribbble {
  @include icon($icon-dribbble) {
    @content; } }
// Expose as class
.icon.-dribbble:before {
  content: $icon-dribbble; }

// Save variable
$icon-drink: "\EA89";
// Save mixin
@mixin icon--drink {
  @include icon($icon-drink) {
    @content; } }
// Expose as class
.icon.-drink:before {
  content: $icon-drink; }

// Save variable
$icon-drive: "\EA8A";
// Save mixin
@mixin icon--drive {
  @include icon($icon-drive) {
    @content; } }
// Expose as class
.icon.-drive:before {
  content: $icon-drive; }

// Save variable
$icon-drop: "\EA8B";
// Save mixin
@mixin icon--drop {
  @include icon($icon-drop) {
    @content; } }
// Expose as class
.icon.-drop:before {
  content: $icon-drop; }

// Save variable
$icon-dropbox: "\EA8C";
// Save mixin
@mixin icon--dropbox {
  @include icon($icon-dropbox) {
    @content; } }
// Expose as class
.icon.-dropbox:before {
  content: $icon-dropbox; }

// Save variable
$icon-edit: "\EA8D";
// Save mixin
@mixin icon--edit {
  @include icon($icon-edit) {
    @content; } }
// Expose as class
.icon.-edit:before {
  content: $icon-edit; }

// Save variable
$icon-email: "\EA8E";
// Save mixin
@mixin icon--email {
  @include icon($icon-email) {
    @content; } }
// Expose as class
.icon.-email:before {
  content: $icon-email; }

// Save variable
$icon-emoji-flirt: "\EA8F";
// Save mixin
@mixin icon--emoji-flirt {
  @include icon($icon-emoji-flirt) {
    @content; } }
// Expose as class
.icon.-emoji-flirt:before {
  content: $icon-emoji-flirt; }

// Save variable
$icon-emoji-happy: "\EA90";
// Save mixin
@mixin icon--emoji-happy {
  @include icon($icon-emoji-happy) {
    @content; } }
// Expose as class
.icon.-emoji-happy:before {
  content: $icon-emoji-happy; }

// Save variable
$icon-emoji-neutral: "\EA91";
// Save mixin
@mixin icon--emoji-neutral {
  @include icon($icon-emoji-neutral) {
    @content; } }
// Expose as class
.icon.-emoji-neutral:before {
  content: $icon-emoji-neutral; }

// Save variable
$icon-emoji-sad: "\EA92";
// Save mixin
@mixin icon--emoji-sad {
  @include icon($icon-emoji-sad) {
    @content; } }
// Expose as class
.icon.-emoji-sad:before {
  content: $icon-emoji-sad; }

// Save variable
$icon-erase: "\EA93";
// Save mixin
@mixin icon--erase {
  @include icon($icon-erase) {
    @content; } }
// Expose as class
.icon.-erase:before {
  content: $icon-erase; }

// Save variable
$icon-eraser: "\EA94";
// Save mixin
@mixin icon--eraser {
  @include icon($icon-eraser) {
    @content; } }
// Expose as class
.icon.-eraser:before {
  content: $icon-eraser; }

// Save variable
$icon-evernote: "\EA95";
// Save mixin
@mixin icon--evernote {
  @include icon($icon-evernote) {
    @content; } }
// Expose as class
.icon.-evernote:before {
  content: $icon-evernote; }

// Save variable
$icon-export: "\EA96";
// Save mixin
@mixin icon--export {
  @include icon($icon-export) {
    @content; } }
// Expose as class
.icon.-export:before {
  content: $icon-export; }

// Save variable
$icon-eye-with-line: "\EA97";
// Save mixin
@mixin icon--eye-with-line {
  @include icon($icon-eye-with-line) {
    @content; } }
// Expose as class
.icon.-eye-with-line:before {
  content: $icon-eye-with-line; }

// Save variable
$icon-eye: "\EA98";
// Save mixin
@mixin icon--eye {
  @include icon($icon-eye) {
    @content; } }
// Expose as class
.icon.-eye:before {
  content: $icon-eye; }

// Save variable
$icon-facebook-with-circle: "\EA99";
// Save mixin
@mixin icon--facebook-with-circle {
  @include icon($icon-facebook-with-circle) {
    @content; } }
// Expose as class
.icon.-facebook-with-circle:before {
  content: $icon-facebook-with-circle; }

// Save variable
$icon-facebook: "\EA9A";
// Save mixin
@mixin icon--facebook {
  @include icon($icon-facebook) {
    @content; } }
// Expose as class
.icon.-facebook:before {
  content: $icon-facebook; }

// Save variable
$icon-feather: "\EA9B";
// Save mixin
@mixin icon--feather {
  @include icon($icon-feather) {
    @content; } }
// Expose as class
.icon.-feather:before {
  content: $icon-feather; }

// Save variable
$icon-fingerprint: "\EA9C";
// Save mixin
@mixin icon--fingerprint {
  @include icon($icon-fingerprint) {
    @content; } }
// Expose as class
.icon.-fingerprint:before {
  content: $icon-fingerprint; }

// Save variable
$icon-flag: "\EA9D";
// Save mixin
@mixin icon--flag {
  @include icon($icon-flag) {
    @content; } }
// Expose as class
.icon.-flag:before {
  content: $icon-flag; }

// Save variable
$icon-flash: "\EA9E";
// Save mixin
@mixin icon--flash {
  @include icon($icon-flash) {
    @content; } }
// Expose as class
.icon.-flash:before {
  content: $icon-flash; }

// Save variable
$icon-flashlight: "\EA9F";
// Save mixin
@mixin icon--flashlight {
  @include icon($icon-flashlight) {
    @content; } }
// Expose as class
.icon.-flashlight:before {
  content: $icon-flashlight; }

// Save variable
$icon-flat-brush: "\EAA0";
// Save mixin
@mixin icon--flat-brush {
  @include icon($icon-flat-brush) {
    @content; } }
// Expose as class
.icon.-flat-brush:before {
  content: $icon-flat-brush; }

// Save variable
$icon-flattr: "\EAA1";
// Save mixin
@mixin icon--flattr {
  @include icon($icon-flattr) {
    @content; } }
// Expose as class
.icon.-flattr:before {
  content: $icon-flattr; }

// Save variable
$icon-flickr-with-circle: "\EAA2";
// Save mixin
@mixin icon--flickr-with-circle {
  @include icon($icon-flickr-with-circle) {
    @content; } }
// Expose as class
.icon.-flickr-with-circle:before {
  content: $icon-flickr-with-circle; }

// Save variable
$icon-flickr: "\EAA3";
// Save mixin
@mixin icon--flickr {
  @include icon($icon-flickr) {
    @content; } }
// Expose as class
.icon.-flickr:before {
  content: $icon-flickr; }

// Save variable
$icon-flow-branch: "\EAA4";
// Save mixin
@mixin icon--flow-branch {
  @include icon($icon-flow-branch) {
    @content; } }
// Expose as class
.icon.-flow-branch:before {
  content: $icon-flow-branch; }

// Save variable
$icon-flow-cascade: "\EAA5";
// Save mixin
@mixin icon--flow-cascade {
  @include icon($icon-flow-cascade) {
    @content; } }
// Expose as class
.icon.-flow-cascade:before {
  content: $icon-flow-cascade; }

// Save variable
$icon-flow-line: "\EAA6";
// Save mixin
@mixin icon--flow-line {
  @include icon($icon-flow-line) {
    @content; } }
// Expose as class
.icon.-flow-line:before {
  content: $icon-flow-line; }

// Save variable
$icon-flow-parallel: "\EAA7";
// Save mixin
@mixin icon--flow-parallel {
  @include icon($icon-flow-parallel) {
    @content; } }
// Expose as class
.icon.-flow-parallel:before {
  content: $icon-flow-parallel; }

// Save variable
$icon-flow-tree: "\EAA8";
// Save mixin
@mixin icon--flow-tree {
  @include icon($icon-flow-tree) {
    @content; } }
// Expose as class
.icon.-flow-tree:before {
  content: $icon-flow-tree; }

// Save variable
$icon-flower: "\EAA9";
// Save mixin
@mixin icon--flower {
  @include icon($icon-flower) {
    @content; } }
// Expose as class
.icon.-flower:before {
  content: $icon-flower; }

// Save variable
$icon-folder-images: "\EAAA";
// Save mixin
@mixin icon--folder-images {
  @include icon($icon-folder-images) {
    @content; } }
// Expose as class
.icon.-folder-images:before {
  content: $icon-folder-images; }

// Save variable
$icon-folder-music: "\EAAB";
// Save mixin
@mixin icon--folder-music {
  @include icon($icon-folder-music) {
    @content; } }
// Expose as class
.icon.-folder-music:before {
  content: $icon-folder-music; }

// Save variable
$icon-folder-video: "\EAAC";
// Save mixin
@mixin icon--folder-video {
  @include icon($icon-folder-video) {
    @content; } }
// Expose as class
.icon.-folder-video:before {
  content: $icon-folder-video; }

// Save variable
$icon-folder: "\EAAD";
// Save mixin
@mixin icon--folder {
  @include icon($icon-folder) {
    @content; } }
// Expose as class
.icon.-folder:before {
  content: $icon-folder; }

// Save variable
$icon-forward: "\EAAE";
// Save mixin
@mixin icon--forward {
  @include icon($icon-forward) {
    @content; } }
// Expose as class
.icon.-forward:before {
  content: $icon-forward; }

// Save variable
$icon-foursquare: "\EAAF";
// Save mixin
@mixin icon--foursquare {
  @include icon($icon-foursquare) {
    @content; } }
// Expose as class
.icon.-foursquare:before {
  content: $icon-foursquare; }

// Save variable
$icon-funnel: "\EAB0";
// Save mixin
@mixin icon--funnel {
  @include icon($icon-funnel) {
    @content; } }
// Expose as class
.icon.-funnel:before {
  content: $icon-funnel; }

// Save variable
$icon-game-controller: "\EAB1";
// Save mixin
@mixin icon--game-controller {
  @include icon($icon-game-controller) {
    @content; } }
// Expose as class
.icon.-game-controller:before {
  content: $icon-game-controller; }

// Save variable
$icon-gauge: "\EAB2";
// Save mixin
@mixin icon--gauge {
  @include icon($icon-gauge) {
    @content; } }
// Expose as class
.icon.-gauge:before {
  content: $icon-gauge; }

// Save variable
$icon-github-with-circle: "\EAB3";
// Save mixin
@mixin icon--github-with-circle {
  @include icon($icon-github-with-circle) {
    @content; } }
// Expose as class
.icon.-github-with-circle:before {
  content: $icon-github-with-circle; }

// Save variable
$icon-github: "\EAB4";
// Save mixin
@mixin icon--github {
  @include icon($icon-github) {
    @content; } }
// Expose as class
.icon.-github:before {
  content: $icon-github; }

// Save variable
$icon-globe: "\EAB5";
// Save mixin
@mixin icon--globe {
  @include icon($icon-globe) {
    @content; } }
// Expose as class
.icon.-globe:before {
  content: $icon-globe; }

// Save variable
$icon-google-with-circle: "\EAB6";
// Save mixin
@mixin icon--google-with-circle {
  @include icon($icon-google-with-circle) {
    @content; } }
// Expose as class
.icon.-google-with-circle:before {
  content: $icon-google-with-circle; }

// Save variable
$icon-google: "\EAB7";
// Save mixin
@mixin icon--google {
  @include icon($icon-google) {
    @content; } }
// Expose as class
.icon.-google:before {
  content: $icon-google; }

// Save variable
$icon-google-drive: "\EAB8";
// Save mixin
@mixin icon--google-drive {
  @include icon($icon-google-drive) {
    @content; } }
// Expose as class
.icon.-google-drive:before {
  content: $icon-google-drive; }

// Save variable
$icon-google-hangouts: "\EAB9";
// Save mixin
@mixin icon--google-hangouts {
  @include icon($icon-google-hangouts) {
    @content; } }
// Expose as class
.icon.-google-hangouts:before {
  content: $icon-google-hangouts; }

// Save variable
$icon-google-play: "\EABA";
// Save mixin
@mixin icon--google-play {
  @include icon($icon-google-play) {
    @content; } }
// Expose as class
.icon.-google-play:before {
  content: $icon-google-play; }

// Save variable
$icon-graduation-cap: "\EABB";
// Save mixin
@mixin icon--graduation-cap {
  @include icon($icon-graduation-cap) {
    @content; } }
// Expose as class
.icon.-graduation-cap:before {
  content: $icon-graduation-cap; }

// Save variable
$icon-grid: "\EABC";
// Save mixin
@mixin icon--grid {
  @include icon($icon-grid) {
    @content; } }
// Expose as class
.icon.-grid:before {
  content: $icon-grid; }

// Save variable
$icon-grooveshark: "\EABD";
// Save mixin
@mixin icon--grooveshark {
  @include icon($icon-grooveshark) {
    @content; } }
// Expose as class
.icon.-grooveshark:before {
  content: $icon-grooveshark; }

// Save variable
$icon-hair-cross: "\EABE";
// Save mixin
@mixin icon--hair-cross {
  @include icon($icon-hair-cross) {
    @content; } }
// Expose as class
.icon.-hair-cross:before {
  content: $icon-hair-cross; }

// Save variable
$icon-hand: "\EABF";
// Save mixin
@mixin icon--hand {
  @include icon($icon-hand) {
    @content; } }
// Expose as class
.icon.-hand:before {
  content: $icon-hand; }

// Save variable
$icon-heart-outlined: "\EAC0";
// Save mixin
@mixin icon--heart-outlined {
  @include icon($icon-heart-outlined) {
    @content; } }
// Expose as class
.icon.-heart-outlined:before {
  content: $icon-heart-outlined; }

// Save variable
$icon-heart: "\EAC1";
// Save mixin
@mixin icon--heart {
  @include icon($icon-heart) {
    @content; } }
// Expose as class
.icon.-heart:before {
  content: $icon-heart; }

// Save variable
$icon-help-with-circle: "\EAC2";
// Save mixin
@mixin icon--help-with-circle {
  @include icon($icon-help-with-circle) {
    @content; } }
// Expose as class
.icon.-help-with-circle:before {
  content: $icon-help-with-circle; }

// Save variable
$icon-help: "\EAC3";
// Save mixin
@mixin icon--help {
  @include icon($icon-help) {
    @content; } }
// Expose as class
.icon.-help:before {
  content: $icon-help; }

// Save variable
$icon-home: "\EAC4";
// Save mixin
@mixin icon--home {
  @include icon($icon-home) {
    @content; } }
// Expose as class
.icon.-home:before {
  content: $icon-home; }

// Save variable
$icon-hour-glass: "\EAC5";
// Save mixin
@mixin icon--hour-glass {
  @include icon($icon-hour-glass) {
    @content; } }
// Expose as class
.icon.-hour-glass:before {
  content: $icon-hour-glass; }

// Save variable
$icon-houzz: "\EAC6";
// Save mixin
@mixin icon--houzz {
  @include icon($icon-houzz) {
    @content; } }
// Expose as class
.icon.-houzz:before {
  content: $icon-houzz; }

// Save variable
$icon-icloud: "\EAC7";
// Save mixin
@mixin icon--icloud {
  @include icon($icon-icloud) {
    @content; } }
// Expose as class
.icon.-icloud:before {
  content: $icon-icloud; }

// Save variable
$icon-image-inverted: "\EAC8";
// Save mixin
@mixin icon--image-inverted {
  @include icon($icon-image-inverted) {
    @content; } }
// Expose as class
.icon.-image-inverted:before {
  content: $icon-image-inverted; }

// Save variable
$icon-image: "\EAC9";
// Save mixin
@mixin icon--image {
  @include icon($icon-image) {
    @content; } }
// Expose as class
.icon.-image:before {
  content: $icon-image; }

// Save variable
$icon-images: "\EACA";
// Save mixin
@mixin icon--images {
  @include icon($icon-images) {
    @content; } }
// Expose as class
.icon.-images:before {
  content: $icon-images; }

// Save variable
$icon-inbox: "\EACB";
// Save mixin
@mixin icon--inbox {
  @include icon($icon-inbox) {
    @content; } }
// Expose as class
.icon.-inbox:before {
  content: $icon-inbox; }

// Save variable
$icon-infinity: "\EACC";
// Save mixin
@mixin icon--infinity {
  @include icon($icon-infinity) {
    @content; } }
// Expose as class
.icon.-infinity:before {
  content: $icon-infinity; }

// Save variable
$icon-info-with-circle: "\EACD";
// Save mixin
@mixin icon--info-with-circle {
  @include icon($icon-info-with-circle) {
    @content; } }
// Expose as class
.icon.-info-with-circle:before {
  content: $icon-info-with-circle; }

// Save variable
$icon-info: "\EACE";
// Save mixin
@mixin icon--info {
  @include icon($icon-info) {
    @content; } }
// Expose as class
.icon.-info:before {
  content: $icon-info; }

// Save variable
$icon-instagram-with-circle: "\EACF";
// Save mixin
@mixin icon--instagram-with-circle {
  @include icon($icon-instagram-with-circle) {
    @content; } }
// Expose as class
.icon.-instagram-with-circle:before {
  content: $icon-instagram-with-circle; }

// Save variable
$icon-instagram: "\EAD0";
// Save mixin
@mixin icon--instagram {
  @include icon($icon-instagram) {
    @content; } }
// Expose as class
.icon.-instagram:before {
  content: $icon-instagram; }

// Save variable
$icon-install: "\EAD1";
// Save mixin
@mixin icon--install {
  @include icon($icon-install) {
    @content; } }
// Expose as class
.icon.-install:before {
  content: $icon-install; }

// Save variable
$icon-key: "\EAD2";
// Save mixin
@mixin icon--key {
  @include icon($icon-key) {
    @content; } }
// Expose as class
.icon.-key:before {
  content: $icon-key; }

// Save variable
$icon-keyboard: "\EAD3";
// Save mixin
@mixin icon--keyboard {
  @include icon($icon-keyboard) {
    @content; } }
// Expose as class
.icon.-keyboard:before {
  content: $icon-keyboard; }

// Save variable
$icon-lab-flask: "\EAD4";
// Save mixin
@mixin icon--lab-flask {
  @include icon($icon-lab-flask) {
    @content; } }
// Expose as class
.icon.-lab-flask:before {
  content: $icon-lab-flask; }

// Save variable
$icon-landline: "\EAD5";
// Save mixin
@mixin icon--landline {
  @include icon($icon-landline) {
    @content; } }
// Expose as class
.icon.-landline:before {
  content: $icon-landline; }

// Save variable
$icon-language: "\EAD6";
// Save mixin
@mixin icon--language {
  @include icon($icon-language) {
    @content; } }
// Expose as class
.icon.-language:before {
  content: $icon-language; }

// Save variable
$icon-laptop: "\EAD7";
// Save mixin
@mixin icon--laptop {
  @include icon($icon-laptop) {
    @content; } }
// Expose as class
.icon.-laptop:before {
  content: $icon-laptop; }

// Save variable
$icon-lastfm-with-circle: "\EAD8";
// Save mixin
@mixin icon--lastfm-with-circle {
  @include icon($icon-lastfm-with-circle) {
    @content; } }
// Expose as class
.icon.-lastfm-with-circle:before {
  content: $icon-lastfm-with-circle; }

// Save variable
$icon-lastfm: "\EAD9";
// Save mixin
@mixin icon--lastfm {
  @include icon($icon-lastfm) {
    @content; } }
// Expose as class
.icon.-lastfm:before {
  content: $icon-lastfm; }

// Save variable
$icon-layers: "\EADA";
// Save mixin
@mixin icon--layers {
  @include icon($icon-layers) {
    @content; } }
// Expose as class
.icon.-layers:before {
  content: $icon-layers; }

// Save variable
$icon-leaf: "\EADB";
// Save mixin
@mixin icon--leaf {
  @include icon($icon-leaf) {
    @content; } }
// Expose as class
.icon.-leaf:before {
  content: $icon-leaf; }

// Save variable
$icon-level-down: "\EADC";
// Save mixin
@mixin icon--level-down {
  @include icon($icon-level-down) {
    @content; } }
// Expose as class
.icon.-level-down:before {
  content: $icon-level-down; }

// Save variable
$icon-level-up: "\EADD";
// Save mixin
@mixin icon--level-up {
  @include icon($icon-level-up) {
    @content; } }
// Expose as class
.icon.-level-up:before {
  content: $icon-level-up; }

// Save variable
$icon-lifebuoy: "\EADE";
// Save mixin
@mixin icon--lifebuoy {
  @include icon($icon-lifebuoy) {
    @content; } }
// Expose as class
.icon.-lifebuoy:before {
  content: $icon-lifebuoy; }

// Save variable
$icon-light-bulb: "\EADF";
// Save mixin
@mixin icon--light-bulb {
  @include icon($icon-light-bulb) {
    @content; } }
// Expose as class
.icon.-light-bulb:before {
  content: $icon-light-bulb; }

// Save variable
$icon-light-down: "\EAE0";
// Save mixin
@mixin icon--light-down {
  @include icon($icon-light-down) {
    @content; } }
// Expose as class
.icon.-light-down:before {
  content: $icon-light-down; }

// Save variable
$icon-light-up: "\EAE1";
// Save mixin
@mixin icon--light-up {
  @include icon($icon-light-up) {
    @content; } }
// Expose as class
.icon.-light-up:before {
  content: $icon-light-up; }

// Save variable
$icon-line-graph: "\EAE2";
// Save mixin
@mixin icon--line-graph {
  @include icon($icon-line-graph) {
    @content; } }
// Expose as class
.icon.-line-graph:before {
  content: $icon-line-graph; }

// Save variable
$icon-link: "\EAE3";
// Save mixin
@mixin icon--link {
  @include icon($icon-link) {
    @content; } }
// Expose as class
.icon.-link:before {
  content: $icon-link; }

// Save variable
$icon-linkedin-with-circle: "\EAE4";
// Save mixin
@mixin icon--linkedin-with-circle {
  @include icon($icon-linkedin-with-circle) {
    @content; } }
// Expose as class
.icon.-linkedin-with-circle:before {
  content: $icon-linkedin-with-circle; }

// Save variable
$icon-linkedin: "\EAE5";
// Save mixin
@mixin icon--linkedin {
  @include icon($icon-linkedin) {
    @content; } }
// Expose as class
.icon.-linkedin:before {
  content: $icon-linkedin; }

// Save variable
$icon-list: "\EAE6";
// Save mixin
@mixin icon--list {
  @include icon($icon-list) {
    @content; } }
// Expose as class
.icon.-list:before {
  content: $icon-list; }

// Save variable
$icon-location-pin: "\EAE7";
// Save mixin
@mixin icon--location-pin {
  @include icon($icon-location-pin) {
    @content; } }
// Expose as class
.icon.-location-pin:before {
  content: $icon-location-pin; }

// Save variable
$icon-location: "\EAE8";
// Save mixin
@mixin icon--location {
  @include icon($icon-location) {
    @content; } }
// Expose as class
.icon.-location:before {
  content: $icon-location; }

// Save variable
$icon-lock-open: "\EAE9";
// Save mixin
@mixin icon--lock-open {
  @include icon($icon-lock-open) {
    @content; } }
// Expose as class
.icon.-lock-open:before {
  content: $icon-lock-open; }

// Save variable
$icon-lock: "\EAEA";
// Save mixin
@mixin icon--lock {
  @include icon($icon-lock) {
    @content; } }
// Expose as class
.icon.-lock:before {
  content: $icon-lock; }

// Save variable
$icon-log-out: "\EAEB";
// Save mixin
@mixin icon--log-out {
  @include icon($icon-log-out) {
    @content; } }
// Expose as class
.icon.-log-out:before {
  content: $icon-log-out; }

// Save variable
$icon-login: "\EAEC";
// Save mixin
@mixin icon--login {
  @include icon($icon-login) {
    @content; } }
// Expose as class
.icon.-login:before {
  content: $icon-login; }

// Save variable
$icon-loop: "\EAED";
// Save mixin
@mixin icon--loop {
  @include icon($icon-loop) {
    @content; } }
// Expose as class
.icon.-loop:before {
  content: $icon-loop; }

// Save variable
$icon-magnet: "\EAEE";
// Save mixin
@mixin icon--magnet {
  @include icon($icon-magnet) {
    @content; } }
// Expose as class
.icon.-magnet:before {
  content: $icon-magnet; }

// Save variable
$icon-mail-with-circle: "\EAF0";
// Save mixin
@mixin icon--mail-with-circle {
  @include icon($icon-mail-with-circle) {
    @content; } }
// Expose as class
.icon.-mail-with-circle:before {
  content: $icon-mail-with-circle; }

// Save variable
$icon-mail: "\EAF1";
// Save mixin
@mixin icon--mail {
  @include icon($icon-mail) {
    @content; } }
// Expose as class
.icon.-mail:before {
  content: $icon-mail; }

// Save variable
$icon-man: "\EAF2";
// Save mixin
@mixin icon--man {
  @include icon($icon-man) {
    @content; } }
// Expose as class
.icon.-man:before {
  content: $icon-man; }

// Save variable
$icon-map: "\EAF3";
// Save mixin
@mixin icon--map {
  @include icon($icon-map) {
    @content; } }
// Expose as class
.icon.-map:before {
  content: $icon-map; }

// Save variable
$icon-mask: "\EAF4";
// Save mixin
@mixin icon--mask {
  @include icon($icon-mask) {
    @content; } }
// Expose as class
.icon.-mask:before {
  content: $icon-mask; }

// Save variable
$icon-medal: "\EAF5";
// Save mixin
@mixin icon--medal {
  @include icon($icon-medal) {
    @content; } }
// Expose as class
.icon.-medal:before {
  content: $icon-medal; }

// Save variable
$icon-medium-with-circle: "\EAF6";
// Save mixin
@mixin icon--medium-with-circle {
  @include icon($icon-medium-with-circle) {
    @content; } }
// Expose as class
.icon.-medium-with-circle:before {
  content: $icon-medium-with-circle; }

// Save variable
$icon-medium: "\EAF7";
// Save mixin
@mixin icon--medium {
  @include icon($icon-medium) {
    @content; } }
// Expose as class
.icon.-medium:before {
  content: $icon-medium; }

// Save variable
$icon-megaphone: "\EAF8";
// Save mixin
@mixin icon--megaphone {
  @include icon($icon-megaphone) {
    @content; } }
// Expose as class
.icon.-megaphone:before {
  content: $icon-megaphone; }

// Save variable
$icon-menu: "\EAF9";
// Save mixin
@mixin icon--menu {
  @include icon($icon-menu) {
    @content; } }
// Expose as class
.icon.-menu:before {
  content: $icon-menu; }

// Save variable
$icon-merge: "\EAFA";
// Save mixin
@mixin icon--merge {
  @include icon($icon-merge) {
    @content; } }
// Expose as class
.icon.-merge:before {
  content: $icon-merge; }

// Save variable
$icon-message: "\EAFB";
// Save mixin
@mixin icon--message {
  @include icon($icon-message) {
    @content; } }
// Expose as class
.icon.-message:before {
  content: $icon-message; }

// Save variable
$icon-mic: "\EAFC";
// Save mixin
@mixin icon--mic {
  @include icon($icon-mic) {
    @content; } }
// Expose as class
.icon.-mic:before {
  content: $icon-mic; }

// Save variable
$icon-minus: "\EAFD";
// Save mixin
@mixin icon--minus {
  @include icon($icon-minus) {
    @content; } }
// Expose as class
.icon.-minus:before {
  content: $icon-minus; }

// Save variable
$icon-mixi: "\EAFE";
// Save mixin
@mixin icon--mixi {
  @include icon($icon-mixi) {
    @content; } }
// Expose as class
.icon.-mixi:before {
  content: $icon-mixi; }

// Save variable
$icon-mobile: "\EAFF";
// Save mixin
@mixin icon--mobile {
  @include icon($icon-mobile) {
    @content; } }
// Expose as class
.icon.-mobile:before {
  content: $icon-mobile; }

// Save variable
$icon-modern-mic: "\EB00";
// Save mixin
@mixin icon--modern-mic {
  @include icon($icon-modern-mic) {
    @content; } }
// Expose as class
.icon.-modern-mic:before {
  content: $icon-modern-mic; }

// Save variable
$icon-moon: "\EB01";
// Save mixin
@mixin icon--moon {
  @include icon($icon-moon) {
    @content; } }
// Expose as class
.icon.-moon:before {
  content: $icon-moon; }

// Save variable
$icon-mouse-pointer: "\EB02";
// Save mixin
@mixin icon--mouse-pointer {
  @include icon($icon-mouse-pointer) {
    @content; } }
// Expose as class
.icon.-mouse-pointer:before {
  content: $icon-mouse-pointer; }

// Save variable
$icon-mouse: "\EB03";
// Save mixin
@mixin icon--mouse {
  @include icon($icon-mouse) {
    @content; } }
// Expose as class
.icon.-mouse:before {
  content: $icon-mouse; }

// Save variable
$icon-music: "\EB04";
// Save mixin
@mixin icon--music {
  @include icon($icon-music) {
    @content; } }
// Expose as class
.icon.-music:before {
  content: $icon-music; }

// Save variable
$icon-network: "\EB05";
// Save mixin
@mixin icon--network {
  @include icon($icon-network) {
    @content; } }
// Expose as class
.icon.-network:before {
  content: $icon-network; }

// Save variable
$icon-new-message: "\EB06";
// Save mixin
@mixin icon--new-message {
  @include icon($icon-new-message) {
    @content; } }
// Expose as class
.icon.-new-message:before {
  content: $icon-new-message; }

// Save variable
$icon-new: "\EB07";
// Save mixin
@mixin icon--new {
  @include icon($icon-new) {
    @content; } }
// Expose as class
.icon.-new:before {
  content: $icon-new; }

// Save variable
$icon-news: "\EB08";
// Save mixin
@mixin icon--news {
  @include icon($icon-news) {
    @content; } }
// Expose as class
.icon.-news:before {
  content: $icon-news; }

// Save variable
$icon-newsletter: "\EB09";
// Save mixin
@mixin icon--newsletter {
  @include icon($icon-newsletter) {
    @content; } }
// Expose as class
.icon.-newsletter:before {
  content: $icon-newsletter; }

// Save variable
$icon-note: "\EB0A";
// Save mixin
@mixin icon--note {
  @include icon($icon-note) {
    @content; } }
// Expose as class
.icon.-note:before {
  content: $icon-note; }

// Save variable
$icon-notification: "\EB0B";
// Save mixin
@mixin icon--notification {
  @include icon($icon-notification) {
    @content; } }
// Expose as class
.icon.-notification:before {
  content: $icon-notification; }

// Save variable
$icon-notifications-off: "\EB0C";
// Save mixin
@mixin icon--notifications-off {
  @include icon($icon-notifications-off) {
    @content; } }
// Expose as class
.icon.-notifications-off:before {
  content: $icon-notifications-off; }

// Save variable
$icon-old-mobile: "\EB0D";
// Save mixin
@mixin icon--old-mobile {
  @include icon($icon-old-mobile) {
    @content; } }
// Expose as class
.icon.-old-mobile:before {
  content: $icon-old-mobile; }

// Save variable
$icon-old-phone: "\EB0E";
// Save mixin
@mixin icon--old-phone {
  @include icon($icon-old-phone) {
    @content; } }
// Expose as class
.icon.-old-phone:before {
  content: $icon-old-phone; }

// Save variable
$icon-onedrive: "\EB0F";
// Save mixin
@mixin icon--onedrive {
  @include icon($icon-onedrive) {
    @content; } }
// Expose as class
.icon.-onedrive:before {
  content: $icon-onedrive; }

// Save variable
$icon-open-book: "\EB10";
// Save mixin
@mixin icon--open-book {
  @include icon($icon-open-book) {
    @content; } }
// Expose as class
.icon.-open-book:before {
  content: $icon-open-book; }

// Save variable
$icon-palette: "\EB11";
// Save mixin
@mixin icon--palette {
  @include icon($icon-palette) {
    @content; } }
// Expose as class
.icon.-palette:before {
  content: $icon-palette; }

// Save variable
$icon-paper-plane: "\EB12";
// Save mixin
@mixin icon--paper-plane {
  @include icon($icon-paper-plane) {
    @content; } }
// Expose as class
.icon.-paper-plane:before {
  content: $icon-paper-plane; }

// Save variable
$icon-paypal: "\EB13";
// Save mixin
@mixin icon--paypal {
  @include icon($icon-paypal) {
    @content; } }
// Expose as class
.icon.-paypal:before {
  content: $icon-paypal; }

// Save variable
$icon-pencil: "\EB14";
// Save mixin
@mixin icon--pencil {
  @include icon($icon-pencil) {
    @content; } }
// Expose as class
.icon.-pencil:before {
  content: $icon-pencil; }

// Save variable
$icon-phone: "\EB15";
// Save mixin
@mixin icon--phone {
  @include icon($icon-phone) {
    @content; } }
// Expose as class
.icon.-phone:before {
  content: $icon-phone; }

// Save variable
$icon-picasa: "\EB16";
// Save mixin
@mixin icon--picasa {
  @include icon($icon-picasa) {
    @content; } }
// Expose as class
.icon.-picasa:before {
  content: $icon-picasa; }

// Save variable
$icon-pie-chart: "\EB17";
// Save mixin
@mixin icon--pie-chart {
  @include icon($icon-pie-chart) {
    @content; } }
// Expose as class
.icon.-pie-chart:before {
  content: $icon-pie-chart; }

// Save variable
$icon-pin: "\EB18";
// Save mixin
@mixin icon--pin {
  @include icon($icon-pin) {
    @content; } }
// Expose as class
.icon.-pin:before {
  content: $icon-pin; }

// Save variable
$icon-pinterest-with-circle: "\EB19";
// Save mixin
@mixin icon--pinterest-with-circle {
  @include icon($icon-pinterest-with-circle) {
    @content; } }
// Expose as class
.icon.-pinterest-with-circle:before {
  content: $icon-pinterest-with-circle; }

// Save variable
$icon-pinterest: "\EB1A";
// Save mixin
@mixin icon--pinterest {
  @include icon($icon-pinterest) {
    @content; } }
// Expose as class
.icon.-pinterest:before {
  content: $icon-pinterest; }

// Save variable
$icon-plus: "\EB1B";
// Save mixin
@mixin icon--plus {
  @include icon($icon-plus) {
    @content; } }
// Expose as class
.icon.-plus:before {
  content: $icon-plus; }

// Save variable
$icon-popup: "\EB1C";
// Save mixin
@mixin icon--popup {
  @include icon($icon-popup) {
    @content; } }
// Expose as class
.icon.-popup:before {
  content: $icon-popup; }

// Save variable
$icon-power-plug: "\EB1D";
// Save mixin
@mixin icon--power-plug {
  @include icon($icon-power-plug) {
    @content; } }
// Expose as class
.icon.-power-plug:before {
  content: $icon-power-plug; }

// Save variable
$icon-price-ribbon: "\EB1E";
// Save mixin
@mixin icon--price-ribbon {
  @include icon($icon-price-ribbon) {
    @content; } }
// Expose as class
.icon.-price-ribbon:before {
  content: $icon-price-ribbon; }

// Save variable
$icon-price-tag: "\EB1F";
// Save mixin
@mixin icon--price-tag {
  @include icon($icon-price-tag) {
    @content; } }
// Expose as class
.icon.-price-tag:before {
  content: $icon-price-tag; }

// Save variable
$icon-print: "\EB20";
// Save mixin
@mixin icon--print {
  @include icon($icon-print) {
    @content; } }
// Expose as class
.icon.-print:before {
  content: $icon-print; }

// Save variable
$icon-progress-empty: "\EB21";
// Save mixin
@mixin icon--progress-empty {
  @include icon($icon-progress-empty) {
    @content; } }
// Expose as class
.icon.-progress-empty:before {
  content: $icon-progress-empty; }

// Save variable
$icon-progress-full: "\EB22";
// Save mixin
@mixin icon--progress-full {
  @include icon($icon-progress-full) {
    @content; } }
// Expose as class
.icon.-progress-full:before {
  content: $icon-progress-full; }

// Save variable
$icon-progress-one: "\EB23";
// Save mixin
@mixin icon--progress-one {
  @include icon($icon-progress-one) {
    @content; } }
// Expose as class
.icon.-progress-one:before {
  content: $icon-progress-one; }

// Save variable
$icon-progress-two: "\EB24";
// Save mixin
@mixin icon--progress-two {
  @include icon($icon-progress-two) {
    @content; } }
// Expose as class
.icon.-progress-two:before {
  content: $icon-progress-two; }

// Save variable
$icon-publish: "\EB25";
// Save mixin
@mixin icon--publish {
  @include icon($icon-publish) {
    @content; } }
// Expose as class
.icon.-publish:before {
  content: $icon-publish; }

// Save variable
$icon-qq-with-circle: "\EB26";
// Save mixin
@mixin icon--qq-with-circle {
  @include icon($icon-qq-with-circle) {
    @content; } }
// Expose as class
.icon.-qq-with-circle:before {
  content: $icon-qq-with-circle; }

// Save variable
$icon-qq: "\EB27";
// Save mixin
@mixin icon--qq {
  @include icon($icon-qq) {
    @content; } }
// Expose as class
.icon.-qq:before {
  content: $icon-qq; }

// Save variable
$icon-quote: "\EB28";
// Save mixin
@mixin icon--quote {
  @include icon($icon-quote) {
    @content; } }
// Expose as class
.icon.-quote:before {
  content: $icon-quote; }

// Save variable
$icon-radio: "\EB29";
// Save mixin
@mixin icon--radio {
  @include icon($icon-radio) {
    @content; } }
// Expose as class
.icon.-radio:before {
  content: $icon-radio; }

// Save variable
$icon-raft-with-circle: "\EB2A";
// Save mixin
@mixin icon--raft-with-circle {
  @include icon($icon-raft-with-circle) {
    @content; } }
// Expose as class
.icon.-raft-with-circle:before {
  content: $icon-raft-with-circle; }

// Save variable
$icon-raft: "\EB2B";
// Save mixin
@mixin icon--raft {
  @include icon($icon-raft) {
    @content; } }
// Expose as class
.icon.-raft:before {
  content: $icon-raft; }

// Save variable
$icon-rainbow: "\EB2C";
// Save mixin
@mixin icon--rainbow {
  @include icon($icon-rainbow) {
    @content; } }
// Expose as class
.icon.-rainbow:before {
  content: $icon-rainbow; }

// Save variable
$icon-rdio-with-circle: "\EB2D";
// Save mixin
@mixin icon--rdio-with-circle {
  @include icon($icon-rdio-with-circle) {
    @content; } }
// Expose as class
.icon.-rdio-with-circle:before {
  content: $icon-rdio-with-circle; }

// Save variable
$icon-rdio: "\EB2E";
// Save mixin
@mixin icon--rdio {
  @include icon($icon-rdio) {
    @content; } }
// Expose as class
.icon.-rdio:before {
  content: $icon-rdio; }

// Save variable
$icon-remove-user: "\EB2F";
// Save mixin
@mixin icon--remove-user {
  @include icon($icon-remove-user) {
    @content; } }
// Expose as class
.icon.-remove-user:before {
  content: $icon-remove-user; }

// Save variable
$icon-renren: "\EB30";
// Save mixin
@mixin icon--renren {
  @include icon($icon-renren) {
    @content; } }
// Expose as class
.icon.-renren:before {
  content: $icon-renren; }

// Save variable
$icon-reply-all: "\EB31";
// Save mixin
@mixin icon--reply-all {
  @include icon($icon-reply-all) {
    @content; } }
// Expose as class
.icon.-reply-all:before {
  content: $icon-reply-all; }

// Save variable
$icon-reply: "\EB32";
// Save mixin
@mixin icon--reply {
  @include icon($icon-reply) {
    @content; } }
// Expose as class
.icon.-reply:before {
  content: $icon-reply; }

// Save variable
$icon-resize-100: "\EB33";
// Save mixin
@mixin icon--resize-100 {
  @include icon($icon-resize-100) {
    @content; } }
// Expose as class
.icon.-resize-100:before {
  content: $icon-resize-100; }

// Save variable
$icon-resize-full-screen: "\EB34";
// Save mixin
@mixin icon--resize-full-screen {
  @include icon($icon-resize-full-screen) {
    @content; } }
// Expose as class
.icon.-resize-full-screen:before {
  content: $icon-resize-full-screen; }

// Save variable
$icon-retweet: "\EB35";
// Save mixin
@mixin icon--retweet {
  @include icon($icon-retweet) {
    @content; } }
// Expose as class
.icon.-retweet:before {
  content: $icon-retweet; }

// Save variable
$icon-rocket: "\EB36";
// Save mixin
@mixin icon--rocket {
  @include icon($icon-rocket) {
    @content; } }
// Expose as class
.icon.-rocket:before {
  content: $icon-rocket; }

// Save variable
$icon-round-brush: "\EB37";
// Save mixin
@mixin icon--round-brush {
  @include icon($icon-round-brush) {
    @content; } }
// Expose as class
.icon.-round-brush:before {
  content: $icon-round-brush; }

// Save variable
$icon-rss: "\EB38";
// Save mixin
@mixin icon--rss {
  @include icon($icon-rss) {
    @content; } }
// Expose as class
.icon.-rss:before {
  content: $icon-rss; }

// Save variable
$icon-ruler: "\EB39";
// Save mixin
@mixin icon--ruler {
  @include icon($icon-ruler) {
    @content; } }
// Expose as class
.icon.-ruler:before {
  content: $icon-ruler; }

// Save variable
$icon-save: "\EB3A";
// Save mixin
@mixin icon--save {
  @include icon($icon-save) {
    @content; } }
// Expose as class
.icon.-save:before {
  content: $icon-save; }

// Save variable
$icon-scissors: "\EB3B";
// Save mixin
@mixin icon--scissors {
  @include icon($icon-scissors) {
    @content; } }
// Expose as class
.icon.-scissors:before {
  content: $icon-scissors; }

// Save variable
$icon-scribd: "\EB3C";
// Save mixin
@mixin icon--scribd {
  @include icon($icon-scribd) {
    @content; } }
// Expose as class
.icon.-scribd:before {
  content: $icon-scribd; }

// Save variable
$icon-select-arrows: "\EB3D";
// Save mixin
@mixin icon--select-arrows {
  @include icon($icon-select-arrows) {
    @content; } }
// Expose as class
.icon.-select-arrows:before {
  content: $icon-select-arrows; }

// Save variable
$icon-share-alternative: "\EB3E";
// Save mixin
@mixin icon--share-alternative {
  @include icon($icon-share-alternative) {
    @content; } }
// Expose as class
.icon.-share-alternative:before {
  content: $icon-share-alternative; }

// Save variable
$icon-share: "\EB3F";
// Save mixin
@mixin icon--share {
  @include icon($icon-share) {
    @content; } }
// Expose as class
.icon.-share:before {
  content: $icon-share; }

// Save variable
$icon-shareable: "\EB40";
// Save mixin
@mixin icon--shareable {
  @include icon($icon-shareable) {
    @content; } }
// Expose as class
.icon.-shareable:before {
  content: $icon-shareable; }

// Save variable
$icon-shield: "\EB41";
// Save mixin
@mixin icon--shield {
  @include icon($icon-shield) {
    @content; } }
// Expose as class
.icon.-shield:before {
  content: $icon-shield; }

// Save variable
$icon-shop: "\EB42";
// Save mixin
@mixin icon--shop {
  @include icon($icon-shop) {
    @content; } }
// Expose as class
.icon.-shop:before {
  content: $icon-shop; }

// Save variable
$icon-shopping-bag: "\EB43";
// Save mixin
@mixin icon--shopping-bag {
  @include icon($icon-shopping-bag) {
    @content; } }
// Expose as class
.icon.-shopping-bag:before {
  content: $icon-shopping-bag; }

// Save variable
$icon-shopping-basket: "\EB44";
// Save mixin
@mixin icon--shopping-basket {
  @include icon($icon-shopping-basket) {
    @content; } }
// Expose as class
.icon.-shopping-basket:before {
  content: $icon-shopping-basket; }

// Save variable
$icon-shopping-cart: "\EB45";
// Save mixin
@mixin icon--shopping-cart {
  @include icon($icon-shopping-cart) {
    @content; } }
// Expose as class
.icon.-shopping-cart:before {
  content: $icon-shopping-cart; }

// Save variable
$icon-shuffle: "\EB46";
// Save mixin
@mixin icon--shuffle {
  @include icon($icon-shuffle) {
    @content; } }
// Expose as class
.icon.-shuffle:before {
  content: $icon-shuffle; }

// Save variable
$icon-signal: "\EB47";
// Save mixin
@mixin icon--signal {
  @include icon($icon-signal) {
    @content; } }
// Expose as class
.icon.-signal:before {
  content: $icon-signal; }

// Save variable
$icon-sina-weibo: "\EB48";
// Save mixin
@mixin icon--sina-weibo {
  @include icon($icon-sina-weibo) {
    @content; } }
// Expose as class
.icon.-sina-weibo:before {
  content: $icon-sina-weibo; }

// Save variable
$icon-skype-with-circle: "\EB49";
// Save mixin
@mixin icon--skype-with-circle {
  @include icon($icon-skype-with-circle) {
    @content; } }
// Expose as class
.icon.-skype-with-circle:before {
  content: $icon-skype-with-circle; }

// Save variable
$icon-skype: "\EB4A";
// Save mixin
@mixin icon--skype {
  @include icon($icon-skype) {
    @content; } }
// Expose as class
.icon.-skype:before {
  content: $icon-skype; }

// Save variable
$icon-slideshare: "\EB4B";
// Save mixin
@mixin icon--slideshare {
  @include icon($icon-slideshare) {
    @content; } }
// Expose as class
.icon.-slideshare:before {
  content: $icon-slideshare; }

// Save variable
$icon-smashing: "\EB4C";
// Save mixin
@mixin icon--smashing {
  @include icon($icon-smashing) {
    @content; } }
// Expose as class
.icon.-smashing:before {
  content: $icon-smashing; }

// Save variable
$icon-sound-mix: "\EB4D";
// Save mixin
@mixin icon--sound-mix {
  @include icon($icon-sound-mix) {
    @content; } }
// Expose as class
.icon.-sound-mix:before {
  content: $icon-sound-mix; }

// Save variable
$icon-sound-mute: "\EB4E";
// Save mixin
@mixin icon--sound-mute {
  @include icon($icon-sound-mute) {
    @content; } }
// Expose as class
.icon.-sound-mute:before {
  content: $icon-sound-mute; }

// Save variable
$icon-sound: "\EB4F";
// Save mixin
@mixin icon--sound {
  @include icon($icon-sound) {
    @content; } }
// Expose as class
.icon.-sound:before {
  content: $icon-sound; }

// Save variable
$icon-soundcloud: "\EB50";
// Save mixin
@mixin icon--soundcloud {
  @include icon($icon-soundcloud) {
    @content; } }
// Expose as class
.icon.-soundcloud:before {
  content: $icon-soundcloud; }

// Save variable
$icon-sports-club: "\EB51";
// Save mixin
@mixin icon--sports-club {
  @include icon($icon-sports-club) {
    @content; } }
// Expose as class
.icon.-sports-club:before {
  content: $icon-sports-club; }

// Save variable
$icon-spotify-with-circle: "\EB52";
// Save mixin
@mixin icon--spotify-with-circle {
  @include icon($icon-spotify-with-circle) {
    @content; } }
// Expose as class
.icon.-spotify-with-circle:before {
  content: $icon-spotify-with-circle; }

// Save variable
$icon-spotify: "\EB53";
// Save mixin
@mixin icon--spotify {
  @include icon($icon-spotify) {
    @content; } }
// Expose as class
.icon.-spotify:before {
  content: $icon-spotify; }

// Save variable
$icon-spreadsheet: "\EB54";
// Save mixin
@mixin icon--spreadsheet {
  @include icon($icon-spreadsheet) {
    @content; } }
// Expose as class
.icon.-spreadsheet:before {
  content: $icon-spreadsheet; }

// Save variable
$icon-squared-cross: "\EB55";
// Save mixin
@mixin icon--squared-cross {
  @include icon($icon-squared-cross) {
    @content; } }
// Expose as class
.icon.-squared-cross:before {
  content: $icon-squared-cross; }

// Save variable
$icon-squared-minus: "\EB56";
// Save mixin
@mixin icon--squared-minus {
  @include icon($icon-squared-minus) {
    @content; } }
// Expose as class
.icon.-squared-minus:before {
  content: $icon-squared-minus; }

// Save variable
$icon-squared-plus: "\EB57";
// Save mixin
@mixin icon--squared-plus {
  @include icon($icon-squared-plus) {
    @content; } }
// Expose as class
.icon.-squared-plus:before {
  content: $icon-squared-plus; }

// Save variable
$icon-star-outlined: "\EB58";
// Save mixin
@mixin icon--star-outlined {
  @include icon($icon-star-outlined) {
    @content; } }
// Expose as class
.icon.-star-outlined:before {
  content: $icon-star-outlined; }

// Save variable
$icon-star: "\EB59";
// Save mixin
@mixin icon--star {
  @include icon($icon-star) {
    @content; } }
// Expose as class
.icon.-star:before {
  content: $icon-star; }

// Save variable
$icon-stopwatch: "\EB5A";
// Save mixin
@mixin icon--stopwatch {
  @include icon($icon-stopwatch) {
    @content; } }
// Expose as class
.icon.-stopwatch:before {
  content: $icon-stopwatch; }

// Save variable
$icon-stumbleupon-with-circle: "\EB5B";
// Save mixin
@mixin icon--stumbleupon-with-circle {
  @include icon($icon-stumbleupon-with-circle) {
    @content; } }
// Expose as class
.icon.-stumbleupon-with-circle:before {
  content: $icon-stumbleupon-with-circle; }

// Save variable
$icon-stumbleupon: "\EB5C";
// Save mixin
@mixin icon--stumbleupon {
  @include icon($icon-stumbleupon) {
    @content; } }
// Expose as class
.icon.-stumbleupon:before {
  content: $icon-stumbleupon; }

// Save variable
$icon-suitcase: "\EB5D";
// Save mixin
@mixin icon--suitcase {
  @include icon($icon-suitcase) {
    @content; } }
// Expose as class
.icon.-suitcase:before {
  content: $icon-suitcase; }

// Save variable
$icon-swap: "\EB5E";
// Save mixin
@mixin icon--swap {
  @include icon($icon-swap) {
    @content; } }
// Expose as class
.icon.-swap:before {
  content: $icon-swap; }

// Save variable
$icon-swarm: "\EB5F";
// Save mixin
@mixin icon--swarm {
  @include icon($icon-swarm) {
    @content; } }
// Expose as class
.icon.-swarm:before {
  content: $icon-swarm; }

// Save variable
$icon-sweden: "\EB60";
// Save mixin
@mixin icon--sweden {
  @include icon($icon-sweden) {
    @content; } }
// Expose as class
.icon.-sweden:before {
  content: $icon-sweden; }

// Save variable
$icon-switch: "\EB61";
// Save mixin
@mixin icon--switch {
  @include icon($icon-switch) {
    @content; } }
// Expose as class
.icon.-switch:before {
  content: $icon-switch; }

// Save variable
$icon-tablet-mobile-combo: "\EB62";
// Save mixin
@mixin icon--tablet-mobile-combo {
  @include icon($icon-tablet-mobile-combo) {
    @content; } }
// Expose as class
.icon.-tablet-mobile-combo:before {
  content: $icon-tablet-mobile-combo; }

// Save variable
$icon-tablet: "\EB63";
// Save mixin
@mixin icon--tablet {
  @include icon($icon-tablet) {
    @content; } }
// Expose as class
.icon.-tablet:before {
  content: $icon-tablet; }

// Save variable
$icon-tag: "\EB64";
// Save mixin
@mixin icon--tag {
  @include icon($icon-tag) {
    @content; } }
// Expose as class
.icon.-tag:before {
  content: $icon-tag; }

// Save variable
$icon-text-document-inverted: "\EB65";
// Save mixin
@mixin icon--text-document-inverted {
  @include icon($icon-text-document-inverted) {
    @content; } }
// Expose as class
.icon.-text-document-inverted:before {
  content: $icon-text-document-inverted; }

// Save variable
$icon-text-document: "\EB66";
// Save mixin
@mixin icon--text-document {
  @include icon($icon-text-document) {
    @content; } }
// Expose as class
.icon.-text-document:before {
  content: $icon-text-document; }

// Save variable
$icon-text: "\EB67";
// Save mixin
@mixin icon--text {
  @include icon($icon-text) {
    @content; } }
// Expose as class
.icon.-text:before {
  content: $icon-text; }

// Save variable
$icon-thermometer: "\EB68";
// Save mixin
@mixin icon--thermometer {
  @include icon($icon-thermometer) {
    @content; } }
// Expose as class
.icon.-thermometer:before {
  content: $icon-thermometer; }

// Save variable
$icon-thumbs-down: "\EB69";
// Save mixin
@mixin icon--thumbs-down {
  @include icon($icon-thumbs-down) {
    @content; } }
// Expose as class
.icon.-thumbs-down:before {
  content: $icon-thumbs-down; }

// Save variable
$icon-thumbs-up: "\EB6A";
// Save mixin
@mixin icon--thumbs-up {
  @include icon($icon-thumbs-up) {
    @content; } }
// Expose as class
.icon.-thumbs-up:before {
  content: $icon-thumbs-up; }

// Save variable
$icon-thunder-cloud: "\EB6B";
// Save mixin
@mixin icon--thunder-cloud {
  @include icon($icon-thunder-cloud) {
    @content; } }
// Expose as class
.icon.-thunder-cloud:before {
  content: $icon-thunder-cloud; }

// Save variable
$icon-ticket: "\EB6C";
// Save mixin
@mixin icon--ticket {
  @include icon($icon-ticket) {
    @content; } }
// Expose as class
.icon.-ticket:before {
  content: $icon-ticket; }

// Save variable
$icon-time-slot: "\EB6D";
// Save mixin
@mixin icon--time-slot {
  @include icon($icon-time-slot) {
    @content; } }
// Expose as class
.icon.-time-slot:before {
  content: $icon-time-slot; }

// Save variable
$icon-tools: "\EB6E";
// Save mixin
@mixin icon--tools {
  @include icon($icon-tools) {
    @content; } }
// Expose as class
.icon.-tools:before {
  content: $icon-tools; }

// Save variable
$icon-traffic-cone: "\EB6F";
// Save mixin
@mixin icon--traffic-cone {
  @include icon($icon-traffic-cone) {
    @content; } }
// Expose as class
.icon.-traffic-cone:before {
  content: $icon-traffic-cone; }

// Save variable
$icon-trash: "\EB70";
// Save mixin
@mixin icon--trash {
  @include icon($icon-trash) {
    @content; } }
// Expose as class
.icon.-trash:before {
  content: $icon-trash; }

// Save variable
$icon-tree: "\EB71";
// Save mixin
@mixin icon--tree {
  @include icon($icon-tree) {
    @content; } }
// Expose as class
.icon.-tree:before {
  content: $icon-tree; }

// Save variable
$icon-triangle-down: "\EB72";
// Save mixin
@mixin icon--triangle-down {
  @include icon($icon-triangle-down) {
    @content; } }
// Expose as class
.icon.-triangle-down:before {
  content: $icon-triangle-down; }

// Save variable
$icon-triangle-left: "\EB73";
// Save mixin
@mixin icon--triangle-left {
  @include icon($icon-triangle-left) {
    @content; } }
// Expose as class
.icon.-triangle-left:before {
  content: $icon-triangle-left; }

// Save variable
$icon-triangle-right: "\EB74";
// Save mixin
@mixin icon--triangle-right {
  @include icon($icon-triangle-right) {
    @content; } }
// Expose as class
.icon.-triangle-right:before {
  content: $icon-triangle-right; }

// Save variable
$icon-triangle-up: "\EB75";
// Save mixin
@mixin icon--triangle-up {
  @include icon($icon-triangle-up) {
    @content; } }
// Expose as class
.icon.-triangle-up:before {
  content: $icon-triangle-up; }

// Save variable
$icon-tripadvisor: "\EB76";
// Save mixin
@mixin icon--tripadvisor {
  @include icon($icon-tripadvisor) {
    @content; } }
// Expose as class
.icon.-tripadvisor:before {
  content: $icon-tripadvisor; }

// Save variable
$icon-trophy: "\EB77";
// Save mixin
@mixin icon--trophy {
  @include icon($icon-trophy) {
    @content; } }
// Expose as class
.icon.-trophy:before {
  content: $icon-trophy; }

// Save variable
$icon-tumblr-with-circle: "\EB78";
// Save mixin
@mixin icon--tumblr-with-circle {
  @include icon($icon-tumblr-with-circle) {
    @content; } }
// Expose as class
.icon.-tumblr-with-circle:before {
  content: $icon-tumblr-with-circle; }

// Save variable
$icon-tumblr: "\EB79";
// Save mixin
@mixin icon--tumblr {
  @include icon($icon-tumblr) {
    @content; } }
// Expose as class
.icon.-tumblr:before {
  content: $icon-tumblr; }

// Save variable
$icon-tv: "\EB7A";
// Save mixin
@mixin icon--tv {
  @include icon($icon-tv) {
    @content; } }
// Expose as class
.icon.-tv:before {
  content: $icon-tv; }

// Save variable
$icon-twitter-with-circle: "\EB7B";
// Save mixin
@mixin icon--twitter-with-circle {
  @include icon($icon-twitter-with-circle) {
    @content; } }
// Expose as class
.icon.-twitter-with-circle:before {
  content: $icon-twitter-with-circle; }

// Save variable
$icon-twitter: "\EB7C";
// Save mixin
@mixin icon--twitter {
  @include icon($icon-twitter) {
    @content; } }
// Expose as class
.icon.-twitter:before {
  content: $icon-twitter; }

// Save variable
$icon-typing: "\EB7D";
// Save mixin
@mixin icon--typing {
  @include icon($icon-typing) {
    @content; } }
// Expose as class
.icon.-typing:before {
  content: $icon-typing; }

// Save variable
$icon-uninstall: "\EB7E";
// Save mixin
@mixin icon--uninstall {
  @include icon($icon-uninstall) {
    @content; } }
// Expose as class
.icon.-uninstall:before {
  content: $icon-uninstall; }

// Save variable
$icon-unread: "\EB7F";
// Save mixin
@mixin icon--unread {
  @include icon($icon-unread) {
    @content; } }
// Expose as class
.icon.-unread:before {
  content: $icon-unread; }

// Save variable
$icon-untag: "\EB80";
// Save mixin
@mixin icon--untag {
  @include icon($icon-untag) {
    @content; } }
// Expose as class
.icon.-untag:before {
  content: $icon-untag; }

// Save variable
$icon-upload-to-cloud: "\EB81";
// Save mixin
@mixin icon--upload-to-cloud {
  @include icon($icon-upload-to-cloud) {
    @content; } }
// Expose as class
.icon.-upload-to-cloud:before {
  content: $icon-upload-to-cloud; }

// Save variable
$icon-upload: "\EB82";
// Save mixin
@mixin icon--upload {
  @include icon($icon-upload) {
    @content; } }
// Expose as class
.icon.-upload:before {
  content: $icon-upload; }

// Save variable
$icon-user: "\EB83";
// Save mixin
@mixin icon--user {
  @include icon($icon-user) {
    @content; } }
// Expose as class
.icon.-user:before {
  content: $icon-user; }

// Save variable
$icon-users: "\EB84";
// Save mixin
@mixin icon--users {
  @include icon($icon-users) {
    @content; } }
// Expose as class
.icon.-users:before {
  content: $icon-users; }

// Save variable
$icon-v-card: "\EB85";
// Save mixin
@mixin icon--v-card {
  @include icon($icon-v-card) {
    @content; } }
// Expose as class
.icon.-v-card:before {
  content: $icon-v-card; }

// Save variable
$icon-video-camera: "\EB86";
// Save mixin
@mixin icon--video-camera {
  @include icon($icon-video-camera) {
    @content; } }
// Expose as class
.icon.-video-camera:before {
  content: $icon-video-camera; }

// Save variable
$icon-video: "\EB87";
// Save mixin
@mixin icon--video {
  @include icon($icon-video) {
    @content; } }
// Expose as class
.icon.-video:before {
  content: $icon-video; }

// Save variable
$icon-vimeo-with-circle: "\EB88";
// Save mixin
@mixin icon--vimeo-with-circle {
  @include icon($icon-vimeo-with-circle) {
    @content; } }
// Expose as class
.icon.-vimeo-with-circle:before {
  content: $icon-vimeo-with-circle; }

// Save variable
$icon-vimeo: "\EB89";
// Save mixin
@mixin icon--vimeo {
  @include icon($icon-vimeo) {
    @content; } }
// Expose as class
.icon.-vimeo:before {
  content: $icon-vimeo; }

// Save variable
$icon-vine-with-circle: "\EB8A";
// Save mixin
@mixin icon--vine-with-circle {
  @include icon($icon-vine-with-circle) {
    @content; } }
// Expose as class
.icon.-vine-with-circle:before {
  content: $icon-vine-with-circle; }

// Save variable
$icon-vine: "\EB8B";
// Save mixin
@mixin icon--vine {
  @include icon($icon-vine) {
    @content; } }
// Expose as class
.icon.-vine:before {
  content: $icon-vine; }

// Save variable
$icon-vinyl: "\EB8C";
// Save mixin
@mixin icon--vinyl {
  @include icon($icon-vinyl) {
    @content; } }
// Expose as class
.icon.-vinyl:before {
  content: $icon-vinyl; }

// Save variable
$icon-vk-alternitive: "\EB8D";
// Save mixin
@mixin icon--vk-alternitive {
  @include icon($icon-vk-alternitive) {
    @content; } }
// Expose as class
.icon.-vk-alternitive:before {
  content: $icon-vk-alternitive; }

// Save variable
$icon-vk-with-circle: "\EB8E";
// Save mixin
@mixin icon--vk-with-circle {
  @include icon($icon-vk-with-circle) {
    @content; } }
// Expose as class
.icon.-vk-with-circle:before {
  content: $icon-vk-with-circle; }

// Save variable
$icon-vk: "\EB8F";
// Save mixin
@mixin icon--vk {
  @include icon($icon-vk) {
    @content; } }
// Expose as class
.icon.-vk:before {
  content: $icon-vk; }

// Save variable
$icon-voicemail: "\EB90";
// Save mixin
@mixin icon--voicemail {
  @include icon($icon-voicemail) {
    @content; } }
// Expose as class
.icon.-voicemail:before {
  content: $icon-voicemail; }

// Save variable
$icon-wallet: "\EB91";
// Save mixin
@mixin icon--wallet {
  @include icon($icon-wallet) {
    @content; } }
// Expose as class
.icon.-wallet:before {
  content: $icon-wallet; }

// Save variable
$icon-warning: "\EB92";
// Save mixin
@mixin icon--warning {
  @include icon($icon-warning) {
    @content; } }
// Expose as class
.icon.-warning:before {
  content: $icon-warning; }

// Save variable
$icon-water: "\EB93";
// Save mixin
@mixin icon--water {
  @include icon($icon-water) {
    @content; } }
// Expose as class
.icon.-water:before {
  content: $icon-water; }

// Save variable
$icon-windows-store: "\EB94";
// Save mixin
@mixin icon--windows-store {
  @include icon($icon-windows-store) {
    @content; } }
// Expose as class
.icon.-windows-store:before {
  content: $icon-windows-store; }

// Save variable
$icon-xing-with-circle: "\EB95";
// Save mixin
@mixin icon--xing-with-circle {
  @include icon($icon-xing-with-circle) {
    @content; } }
// Expose as class
.icon.-xing-with-circle:before {
  content: $icon-xing-with-circle; }

// Save variable
$icon-xing: "\EB96";
// Save mixin
@mixin icon--xing {
  @include icon($icon-xing) {
    @content; } }
// Expose as class
.icon.-xing:before {
  content: $icon-xing; }

// Save variable
$icon-yelp: "\EB97";
// Save mixin
@mixin icon--yelp {
  @include icon($icon-yelp) {
    @content; } }
// Expose as class
.icon.-yelp:before {
  content: $icon-yelp; }

// Save variable
$icon-youko-with-circle: "\EB98";
// Save mixin
@mixin icon--youko-with-circle {
  @include icon($icon-youko-with-circle) {
    @content; } }
// Expose as class
.icon.-youko-with-circle:before {
  content: $icon-youko-with-circle; }

// Save variable
$icon-youko: "\EB99";
// Save mixin
@mixin icon--youko {
  @include icon($icon-youko) {
    @content; } }
// Expose as class
.icon.-youko:before {
  content: $icon-youko; }

// Save variable
$icon-youtube-with-circle: "\EB9A";
// Save mixin
@mixin icon--youtube-with-circle {
  @include icon($icon-youtube-with-circle) {
    @content; } }
// Expose as class
.icon.-youtube-with-circle:before {
  content: $icon-youtube-with-circle; }

// Save variable
$icon-youtube: "\EB9B";
// Save mixin
@mixin icon--youtube {
  @include icon($icon-youtube) {
    @content; } }
// Expose as class
.icon.-youtube:before {
  content: $icon-youtube; }


