/* -------------------------
  Buttons
  --------------------------- */
.btn-purple {
  background: $purple;
  color: white;
  padding: 10px 20px;
  border: none;
  text-transform: uppercase;
  @include lato-bold;
  @include fontsize-rem(14px);
  letter-spacing: 2px;
  &:hover, &:focus, &:active {
    background: $purple2; } }
.btn-full {
  width: 100%; }
