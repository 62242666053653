/* -------------------------
  Bootstrap
  --------------------------- */

// Prevent overriding bootstrap styles by importing only variables & mixins
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables.scss";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins.scss";

// Add 480px breakpoint
@import "./mid_small_breakpoint";
