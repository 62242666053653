/* -------------------------
  News specific styles
  --------------------------- */
.news {
  .featured-image {
    img {
      @include breakpoint($phone) {
        max-width: 100%;
        height: auto; } } }
  article {
    @include clearfix;
    position: relative;
    margin: 75px 0;
    @include breakpoint($phone) {
      margin: 30px 0; }
    .featured-image {
      display: inline-block;
      overflow: hidden;
      padding: 0;
      border: none;
      border-radius: 0;
      transition: all 0.5s;
      @include breakpoint($phone) {
        float: none !important; }
      img {
        transition: all 0.5s;
        min-width: 100%;
        @include breakpoint($phone) {
          max-width: 100%;
          height: auto; } }
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 0.5s; } } }
    .details {
      width: 400px;
      padding: 30px 50px;
      background: white;
      border: 1px solid $grey-e;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      transition: all 0.4s;
      z-index: 100;
      @include breakpoint($phone) {
        width: auto;
        transform: none;
        position: relative; }
      &:hover {
        border-color: $grey-c; }
      .date {
        @include fontsize-rem(14px);
        @include lato-bold;
        color: $grey-9;
        text-transform: uppercase;
        letter-spacing: 2px; }
      hr {
        width: 30px;
        border: 1px solid $grey-6; }
      h3 {
        line-height: 1.5em;
        a {
          color: $grey-6;
          transition: all 0.4s;
          &:hover {
            color: $purple;
            text-decoration: none; } } } }
    &:first-child {
      margin-top: 0; }

    &:nth-child(2n) {
      .featured-image {
        float: right; }
      .details {
        left: 0; } } }

  // Single news page
  .featured-image.full img {
    width: 100%; }
  .single-news {
    margin: -60px 30px 0 30px;
    background: white;
    position: relative;
    padding: 0 30px;
    @include breakpoint($phone) {
      margin: 20px 0;
      padding: 0; }
    header {
      padding: 20px 0;
      .date {
        @include fontsize-rem(14px);
        color: $grey-9;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 20px 0; }
      h3 {
        @include fontsize-rem(24px);
        @include breakpoint($phone) {
          line-height: 1.5em;
          @include fontsize-rem(20px); } }
      hr {
        width: 50px;
        border: 1px solid black;
        margin: 30px auto; } }

    article {
      margin: 0 0 30px 0;
      p {
        @include fontsize-rem(15px);
        color: $grey-6;
        line-height: 1.7em;
        margin-bottom: 20px; } }

    .meta {
      h4 {
        float: left;
        @include breakpoint($phone) {
          float: none; } }
      .categories {
        @include clearfix;
        margin: 15px 0;
        ul {
          margin-left: 180px;
          padding-left: 0;
          @include breakpoint($phone) {
            margin-left: 0; }
          li {
            list-style: none;
            display: inline-block;
            margin: 8px 0;
            &:last-child {
              &:after {
                display: none; } }
            &:after {
              content: "";
              width: 15px;
              height: 1px;
              border-top: 1px solid $grey-9;
              display: inline-block;
              vertical-align: middle;
              margin: 0 10px; }
            a {
              @include fontsize-rem(14px);
              @include open-sans-regular;
              color: $grey-6; } } } }
      .tags {
        @include clearfix;
        margin: 15px 0;
        .tag-list {
          margin-left: 90px;
          @include breakpoint($phone) {
            margin-left: 0; }
          a {
            color: $grey-6;
            display: inline-block;
            padding: 5px 15px;
            @include fontsize-rem(12px);
            @include lato-bold;
            text-transform: uppercase;
            background: $grey-f2;
            margin: 5px;
            margin-right: 0;
            letter-spacing: 2px;
            transition: all 0.4s;
            @include breakpoint($phone) {
              margin: 5px;
              margin-left: 0; }
            &:hover {
              background: $purple;
              color: white;
              text-decoration: none; } } } } }
    .socials {
      margin: 40px 0;
      padding: 40px 0;
      border-top: 1px solid $grey-f2;
      border-bottom: 1px solid $grey-f2;
      ul {
        padding: 0;
        margin: 0;
        list-style: none; } } } }

aside {
  padding-left: 20px;
  margin-top: 50px;
  @include breakpoint($phone) {
    padding-left: 0; }
  .box {
    margin: 50px 0;
    &.search {
      position: relative;
      input {
        text-align: left; }
      button {
        position: absolute;
        right: 20px;
        top: -18px;
        background: none;
        border: none;
        padding: 0;
        i {
          @include fontsize-rem(32px);
          color: $purple; } } }
    &.best-sellers {
      h5 {
        margin-top: 15px !important;
        margin-bottom: 5px !important; } }
    &.tags {
      .tag-list {
        margin-left: -5px;
        a {
          color: $grey-6;
          display: inline-block;
          padding: 5px 15px;
          @include fontsize-rem(12px);
          @include lato-bold;
          text-transform: uppercase;
          background: $grey-f2;
          margin: 5px;
          margin-right: 0;
          letter-spacing: 2px;
          transition: all 0.4s;
          &:hover {
            background: $purple;
            color: white;
            text-decoration: none; } } } }

    &.subscribe {
      button {
        width: 100%;
        margin-top: 10px;
        padding: 15px 20px; } }

    &.instagram {
      .instagram-section {
        margin-left: -5px; }
      a {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: 5px;
        img {
          max-width: 100%;
          height: auto; } } }


    hr {
      width: 20px;
      border: 1px solid $grey-6;
      margin: 20px 0 30px 0; }
    ul {
      padding-left: 0;
      li {
        list-style: inside;
        margin: 8px 0;
        a {
          @include fontsize-rem(16px);
          @include open-sans-regular;
          color: $grey-6; } } }

    .tile {
      margin: 10px 0;
      @include clearfix;
      .featured-image {
        float: left;
        img {
          width: 70px;
          height: 70px; } }
      .price {
        @include lato-bold;
        @include fontsize-rem(15px);
        color: $brown;
        margin-left: 90px; }
      .date {
        margin-left: 90px;
        @include fontsize-rem(13px);
        @include open-sans-regular;
        color: $grey-9;
        margin-top: 10px;
        margin-bottom: 5px; }
      h5 {
        margin-left: 90px;
        text-transform: none;
        letter-spacing: normal;
        @include fontsize-rem(16px);
        margin-top: 5px;
        a {
          color: $grey-6; } } } } }

// Mansory Style

.mansory {
  @include clearfix;
  .news {
    @include clearfix;
    margin: 0 -15px; }
  article {
    width: 33.333333%;
    @include breakpoint($tablet) {
      width: 50%; }
    @include breakpoint($phone) {
      width: 100%; }
    float: left;
    overflow: hidden;
    margin: 15px 0 !important;
    padding: 0 15px;
    .featured-image {
      float: none !important; }
    img {
      max-width: 100%;
      height: auto; }
    .details {
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      transform: inherit;
      width: auto;
      padding: 30px 40px;
      margin-top: -5px; } } }


