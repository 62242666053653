/* -------------------------
  Side bar menu
  --------------------------- */
ul.side-menu {
  padding: 0;
  margin-top: 3rem;
  li {
    display: block;
    width: 100%;
    padding: 0.3rem 0;
    &.selected {
      a {
        color: $purple; } }
    a {
      color: $grey-c;
      text-transform: uppercase;
      @include fontsize-rem(16px);
      @include lato-bold;
      letter-spacing: 4px;
      @include transition(all 0.35s);
      &:hover {
        text-decoration: none;
        color: $purple; } } } }
