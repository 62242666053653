/* -------------------------
  Colors
  --------------------------- */
$white:           #FFFFFF;
$black:           #1b171c;

// Greyscale tones
$grey-1:          #111;
$grey-2:          #222;
$grey-3:          #333;
$grey-4:          #444;
$grey-5:          #555;
$grey-6:          #666;
$grey-7:          #777;
$grey-8:          #888;
$grey-9:          #999;
$grey-a:          #aaa;
$grey-b:          #bbb;
$grey-c:          #ccc;
$grey-d:          #ddd;
$grey-e:          #eee;
$grey-26:         #262626;
$grey-f2:         #f2f2f2;
$grey-f7:         #f8f6f7;

// Brand colors
$purple:          #735768;
$purple2:         #614a58;
$purple3:         #f2f0f1;
$brown:           #9e6335;
$fb-color:        #3b5998;
$tw-color:        #41abe1;
$in-color:        #325c86;
$gp-color:        #c34536;
$vm-color:        #17b3e8;
$pink-blockquote: #f8f6f7;

//
$share-inactive:  #5c6578;
$share-active:    #588c9a;
