/* -------------------------
  Header
  --------------------------- */
header.top {
  width: 100%;
  z-index: 3;
  position: relative;
  background-color: transparent;
  @include transition(all 0.5s);
  &.default {
    #li-logo {
      display: none; } }
  &.in-page {
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 25px;
    border-bottom: 2px solid $purple;
    @include breakpoint($phone) {
      padding: 10px 20px; }
    @include breakpoint($tablet-portrait) {
      padding: 10px 20px; }
    li {
      margin: 0 3.5rem; }
    .navbar {
      min-height: 0;
      margin-bottom: 28px;
      @include breakpoint($phone) {
        margin-bottom: 0; }
      @include breakpoint($tablet-portrait) {
        margin-bottom: 0; }
      #li-logo {
        display: inline-block;
        img {
          width: 45px; } } }
    .logo-side {
      display: none;
      @include breakpoint($phone) {
        display: block;
        margin: 0;
        img {
          width: 150px;
          margin-top: 3px; } }
      @include breakpoint($tablet-portrait) {
        display: block;
        margin: 0;
        float: left;
        img {
          width: 150px;
          margin-top: 3px; } } }
    .navbar-toggle {
      @include breakpoint($phone) {
        margin: 0 !important; }
      @include breakpoint($tablet-portrait) {
        margin: 0 !important; } } }
  nav.navbar {
    background: none;
    border: 0;
    ul.nav {
      list-style: none;
      padding: 0;
      text-align: center;
      width: 100%;
      li {
        display: inline-block;
        margin: 0 1.6rem;
        @include breakpoint($laptop) {
          margin: 0 1rem; }
        @include breakpoint($tablet) {
          margin: 0 0.4rem; }
        float: none;
        position: relative;
        a {
          color: $white;
          @include fontsize-rem(12px);
          @include breakpoint($laptop) {
            @include fontsize-rem(10px); }
          @include breakpoint($tablet) {
            @include fontsize-rem(10px); }
          text-transform: uppercase;
          display: block;
          padding: 0 0 2px 0;
          position: relative;
          letter-spacing: 2px;
          @include lato-bold;
          &:after {
            display: block;
            position: absolute;
            bottom: -2px;
            width: 0;
            height: 2px;
            content: " ";
            background: $white;
            @include transition(width 0.35s); }
          &:hover {
            &:after {
              width: 100%; } } }
        &.active {
          ul.submenu {
            a:after {
              width: 0; } }
          &:hover {
            ul.submenu {
              a:hover:after {
                width: 100%; } } }
          a {
            background: none;
            color: $white;
            &:after {
              width: 100%; } } }
        &:hover {
          .submenu {
            opacity: 1;
            display: block; } }
        .submenu {
          position: absolute;
          display: none;
          left: 50%;
          top: 100%;
          opacity: 0;
          width: 180px;
          margin-top: 15px;
          margin-left: -90px;
          @include transition(all 0.35s);
          padding: 0.5rem 0;
          background: $purple;
          border: 2px solid $white;
          &:before {
            display: block;
            content: " ";
            position: absolute;
            top: -15px;
            width: 100%;
            height: 15px; }
          @include breakpoint($phone) {
            display: block;
            position: relative;
            width: 100%;
            border: 0;
            margin: 0;
            opacity: 1;
            left: 0;
            &:before {
              display: none; } }
          li {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0.35rem 1rem;
            &.price {
              color: $white; }
            a:hover {
              text-decoration: none; } } } } }
    .navbar-collapse {
      border: none;
      box-shadow: none;
      @include breakpoint($phone) {
        height: 400px; }
      overflow-y: scroll;
      .navbar-nav {
        @include breakpoint($phone) {
          margin: 40px 0;
          li {
            display: block;
            margin: 20px 0;
            &#li-logo {
              display: none; }
            a {
              @include fontsize-rem(14px);
              &:after {
                display: none; } } } }

        @include breakpoint($tablet-portrait) {
          margin: 40px 0;
          li {
            display: block;
            margin: 20px 0;
            &#li-logo {
              display: none; }
            a {
              @include fontsize-rem(14px);
              &:after {
                display: none; } } } } } }

    .navbar-toggle {
      border: none;
      margin-right: 20px;
      &:focus, &:hover {
        background: none; }
      .icon-bar {
        background: white;
        width: 25px;
        margin: 5px 0; } } } }
