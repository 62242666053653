/* -------------------------
  Mixins
  --------------------------- */

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%); }
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%); }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%); } }

// Clearfix

@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table; }
  &:after {
    clear: both; } }

// Calculate REMS FROM PX

$base-font-size: 16px;

@function calculateRem($size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem; }

@mixin fontsize-rem($size) {
  font-size: $size;
  font-size: calculateRem($size); }
