/* -------------------------
  Footer
  --------------------------- */
footer {
  min-height: 300px;
  padding: 80px 0 60px;
  background-color: $black;
  color: $white;
  text-align: center;
  z-index: 1;
  position: relative;
  .logo-side {
    @include breakpoint($phone) {
      float: none; } }
  p {
    @include fontsize-rem(10px);
    text-transform: uppercase;
    text-align: center;
    @include lato-bold;
    margin: 40px 0;
    letter-spacing: 3px;
    line-height: 2rem;
    &.copyright {
      margin: 0; } }
  ul.social-links {
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 0.5rem;
      a {
        color: $white;
        @include fontsize-rem(22px);
        @include transition(color 0.35s);
        &:hover {
          text-decoration: none;
          i {
            color: $grey-c !important; } }
        i {
          color: white !important; } } } }
  .instagram-section {
    margin: 80px 0;
    a {
      @include breakpoint($phone) {
        margin: 5px;
        display: inline-block; } }
    img {
      width: 100px; } }
  .subscribe-side {
    margin-bottom: 120px;
    @include breakpoint($tablet-portrait) {
      width: 70%;
      margin: 0 auto; }
    h3 {
      color: $white;
      margin-bottom: 2rem; }
    input[type="text"] {
      background-color: transparent;
      border-color: $white;
      @include transition(all 0.35s);
      &:focus {
        background-color: rgba($white, 0.5); } }
    button {
      padding: 1rem; } } }
