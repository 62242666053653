/* -------------------------
  Logo
  --------------------------- */
.logo-side {
  margin: 3rem auto;
  text-align: center;
  @include breakpoint($phone) {
    float: left;
    margin: 1.5rem auto 1.5rem 20px; }
  img {
    width: 190px; } }
