/* -------------------------
  Meta - category and tags
  --------------------------- */
.meta {
  text-align: center;
  margin: 50px 0;
  .categories {
    @include clearfix;
    ul {
      padding-left: 0;
      li {
        list-style: none;
        display: inline-block;
        margin: 8px 0;
        &:last-child {
          &:after {
            display: none; } }
        &:after {
          content: "";
          width: 15px;
          height: 1px;
          border-top: 1px solid $grey-9;
          display: inline-block;
          vertical-align: middle;
          margin: 0 10px; }
        a {
          @include fontsize-rem(14px);
          @include open-sans-regular;
          color: $grey-6; } } } }
  .tags {
    @include clearfix;
    .tag-list {
      margin-left: -5px;
      margin-top: 20px;
      a {
        color: $grey-6;
        display: inline-block;
        padding: 5px 15px;
        @include fontsize-rem(12px);
        @include lato-bold;
        text-transform: uppercase;
        background: $grey-f2;
        margin: 5px;
        margin-right: 0;
        letter-spacing: 2px;
        transition: all 0.4s;
        &:hover {
          background: $purple;
          color: white;
          text-decoration: none; } } } } }
