/* -------------------------
  Checkout specific styles
  --------------------------- */
.checkout {
  padding: 70px 0 120px 0;
  h4 {
    text-align: center;
    margin-bottom: 40px;
    label, input {
      margin: 0; }
    input {
      margin-left: 10px;
      margin-top: 3px;
      display: inline-block;
      vertical-align: top; } }
  input {
    margin-bottom: 25px; }
  .order {
    margin-top: 30px;
    table {
      width: 100%;
      td {
        border-top: 1px solid $grey-c;
        border-bottom: 1px solid $grey-c;
        padding: 15px 20px;
        @include fontsize-rem(16px);
        &:first-child {
          text-transform: uppercase;
          @include fontsize-rem(14px); } } } }
  .payment-type {
    background: $purple3;
    padding: 25px;
    margin-top: 40px;
    .form-field {
      margin-bottom: 25px;
      &.submit {
        @include clearfix;
        margin-bottom: 0;
        button {
          float: right;
          padding: 15px 40px; } }
      img {
        height: 15px; }
      p {
        @include fontsize-rem(14px);
        color: $grey-6;
        line-height: 1.5em;
        margin: 20px 0 20px 25px; }
      input {
        margin-top: 6px;
        display: inline-block;
        vertical-align: top; }
      label {
        @include fontsize-rem(15px);
        color: $grey-6;
        margin-left: 8px;
        margin-right: 10px; } } } }
