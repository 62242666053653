/* -------------------------
  Shop specific styles
  --------------------------- */
.shop-filter {
  margin: 70px 0;
  position: relative;
  text-align: center;
  @include breakpoint($phone) {
    margin: 40px 0; }
  ul {
    display: inline-block;
    text-align: center;
    margin-top: -10px;
    li {
      display: inline-block;
      @include fontsize-rem(13px);
      @include lato-bold;
      @include breakpoint($phone) {
        margin: 10px 0; }
      &:last-child {
        &:after {
          display: none; } }
      &:after {
        content: "|";
        width: 1px;
        height: 10px;
        margin: 0 20px;
        color: $grey-c; }
      &.active {
        a {
          color: $brown; } }
      a {
        @include fontsize-rem(13px);
        text-transform: uppercase;
        @include lato-bold;
        letter-spacing: 2px;
        color: $purple; } } } }

.shop-grid {
  text-align: center;
  margin: 50px -15px;
  @include breakpoint($phone) {
    margin: 20px -15px; }
  @include clearfix;
  .tile {
    margin-bottom: 70px; }
  .thumbnail {
    overflow: hidden;
    padding: 0;
    border: none;
    display: block;
    border-radius: 0;
    transition: all 0.5s;
    position: relative;
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      cursor: pointer;
      height: 100%;
      background: rgba(27, 23, 28, 0.7);
      opacity: 0;
      transition: all 0.5s;
      display: table-cell;
      button {
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%);
        display: inline-block;
        border: 2px solid white;
        color: white;
        text-transform: uppercase;
        @include fontsize-rem(11px);
        @include lato-bold;
        letter-spacing: 2px;
        @include breakpoint($phone) {
          background: white;
          color: $grey-26; }
        @include breakpoint($tablet-portrait) {
          background: white;
          color: $grey-26; }
        &:hover {
          background: white;
          color: $grey-26;
          text-decoration: none; } } }
    img {
      transition: all 0.5s;
      min-width: 100%; }
    &:hover {
      img {
        transform: scale(1.1);
        transition: all 0.5s; }
      .overlay {
        opacity: 1; } } }
  .tags {
    margin: 20px 0;
    @include lato-regular;
    color: $grey-9;
    text-transform: uppercase;
    @include fontsize-rem(14px);
    letter-spacing: 2px;
    li {
      display: inline-block;
      list-style: none;
      margin-right: 8px;
      &:after {
        content: ","; }
      &:last-child {
        &:after {
          display: none; } }
      a {
        color: $purple; } } }
  hr {
    border-color: $grey-6;
    width: 25px;
    border-width: 2px; }
  .title {
    line-height: 1.3em;
    a {
      color: $grey-6;
      &:hover {
        color: purple;
        text-decoration: none; } } }

  .price {
    color: $brown;
    @include lato-bold;
    @include fontsize-rem(18px);
    letter-spacing: 2px;
    margin-bottom: 3.5rem; } }

//Shop Details

.shop-details {
  margin: 50px auto;
  .product-info {
    text-align: center;
    @include breakpoint($phone) {
      margin-top: 50px; }
    .price {
      margin: 20px 0;
      color: $brown;
      @include lato-bold;
      @include fontsize-rem(22px); }
    hr {
      width: 50px;
      border: 1px solid $grey-6;
      margin: 30px auto; }
    .small-description {
      @include open-sans-regular;
      @include fontsize-rem(16px);
      color: $grey-6;
      line-height: 1.5em; }
    .controls {
      margin: 40px 0;
      input[type="number"] {
        width: 80px;
        padding: 10px 5px 10px 20px;
        text-align: center; }
      button {
        padding: 12px 20px;
        margin-left: 10px; } } } }

.product-description {
  padding: 50px 0;
  .nav-tabs {
    text-align: center;
    li {
      display: inline-block;
      float: none;
      &.active {
        a {
          color: $grey-6; } }
      a {
        color: $purple; } } }
  .tab-content {
    padding: 50px 0 0 0;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    p {
      @include fontsize-rem(15px);
      color: $grey-6;
      line-height: 1.7em;
      margin-bottom: 20px; }

    .reviews {
      max-width: 600px;
      margin: 0 auto; }

    .review-box {
      margin: 40px 0;
      position: relative;
      text-align: left;
      @include clearfix;
      &:first-child {
        margin-top: 20px; }
      .author {
        display: block;
        width: 80px;
        height: 80px;
        float: left;
        img {
          max-width: 100%;
          height: auto;
          border-radius: 100%; } }
      .review {
        margin-left: 110px;

        .title {
          @include lato-bold;
          @include fontsize-rem(16px);
          color: $grey-3;
          text-transform: none;
          letter-spacing: normal;
          margin-top: 0;
          margin-bottom: 5px; }
        .date {
          @include lato-regular;
          @include fontsize-rem(12px);
          color: $grey-9; }
        .review-text {
          @include open-sans-regular;
          @include fontsize-rem(14px);
          color: $grey-6;
          line-height: 1.7em; }
        .reply {
          position: absolute;
          right: 0;
          top: 0;
          color: $purple;
          @include fontsize-rem(12px);
          text-transform: uppercase;
          letter-spacing: 2px;
          display: inline-block; } } }

    .leave-review {
      max-width: 600px;
      margin: 70px auto;
      form {
        margin-top: 30px;
        input, textarea {
          margin: 10px 0; }
        button {
          width: 100%;
          padding: 17px 25px; } } } } }


