/* -------------------------
  Success specific styles
  --------------------------- */
.success-page {
  padding: 150px;
  min-height: 600px;
  @include breakpoint($tablet) {
      padding: 50px; }
  @include breakpoint($phone) {
    padding: 0; }
  .box {
    background: white;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    width: 600px;
    margin: 0 auto;
    padding: 100px 0;
    text-align: center;
    @include breakpoint($phone) {
      width: 100%; }
    h1 {
      @include lato-bold;
      @include fontsize-rem(100px);
      @include breakpoint($phone) {
        @include fontsize-rem(40px); }
      color: $grey-c;
      margin: 0; }
    p {
      color: $grey-6;
      margin: 40px 0; }
    a {
      color: white;
      display: inline-block;
      padding: 10px 60px;
      &:hover {
        text-decoration: none; } } } }
