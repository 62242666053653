/* -------------------------
  Cart specific styles
  --------------------------- */
table.cart {
  width: 100%;
  margin: 70px auto;
  thead {
    th {
      padding: 10px 5px;
      color: $grey-3;
      @include fontsize-rem(14px);
      @include lato-bold;
      text-transform: uppercase;
      border-bottom: 2px solid $grey-6;
      text-align: left; } }
  tbody {
    td {
      padding: 20px 5px;
      @include lato-bold;
      color: $grey-6;
      @include fontsize-rem(16px);
      border-bottom: 1px solid $grey-c;
      .product-image {
        @include breakpoint($phone) {
          display: block;
          margin-bottom: 20px; }
        &:hover {
          text-decoration: none; }
        img {
          width: 70px;
          height: 70px; } }
      .product-title {
        color: $grey-6;
        @include fontsize-rem(18px);
        @include lato-regular;
        margin-left: 20px;
        @include breakpoint($phone) {
          margin-left: 0;
          line-height: 1.0em; } }
      .quantity {
        width: 80px;
        padding: 10px 5px 10px 20px;
        text-align: center; } } } }

.update-cart {
  margin-bottom: 70px;
  @include clearfix;
  input[type="text"] {
    width: 190px;
    margin-right: 20px;
    @include breakpoint($phone) {
      margin-right: 0;
      float: right;
      display: block;
      width: 100%;
      margin-bottom: 20px; } }
  button {
    padding: 17px 25px;
    @include breakpoint($phone) {
      padding: 14px 25px;
      @include fontsize-rem(12px); } } }

table.cart-totals {
  margin-bottom: 70px;
  td {
    padding: 15px 0;
    width: 50%;
    color: $grey-6;
    @include fontsize-rem(16px);
    @include lato-regular;
    border-bottom: 1px solid $grey-c;
    a {
      color: $purple;
      display: inline-block;
      margin: 10px 0; }
    &:first-child {
      text-transform: uppercase;
      @include lato-bold;
      @include fontsize-rem(14px);
      letter-spacing: 2px;
      width: 70%; } }
  tr {
    &:last-child {
      td {
        @include fontsize-rem(18px);
        @include lato-bold;
        border: none; } } } }

.delivery {
  margin-bottom: 70px;
  button {
    padding: 17px 25px; } }

