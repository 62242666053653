/* -------------------------
  Comments
  --------------------------- */
.comments {
  .comment-box {
    margin: 40px 0;
    position: relative;
    @include clearfix;
    .author {
      display: block;
      width: 80px;
      height: 80px;
      float: left;
      img {
        max-width: 100%;
        height: auto;
        border-radius: 100%; } }
    .comment {
      margin-left: 110px;
      .title {
        @include lato-bold;
        @include fontsize-rem(16px);
        color: $grey-3;
        text-transform: none;
        letter-spacing: normal;
        margin-top: 0;
        margin-bottom: 5px; }
      .date {
        @include lato-regular;
        @include fontsize-rem(12px);
        color: $grey-9; }
      .comment-text {
        @include open-sans-regular;
        @include fontsize-rem(14px);
        color: $grey-6;
        line-height: 1.7em; }
      .reply {
        position: absolute;
        right: 0;
        top: 0;
        color: $purple;
        @include fontsize-rem(12px);
        text-transform: uppercase;
        letter-spacing: 2px;
        display: inline-block; } } } }
.leave-comment {
  margin: 70px 0;
  form {
    margin-top: 30px;
    input, textarea {
      margin: 15px 0; }
    button {
      width: 100%;
      padding: 17px 25px; } } }
