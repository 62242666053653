/* -------------------------
  Share
  --------------------------- */
ul.share-block {
  margin: 2rem 0 4rem;
  padding: 0;
  text-align: center;
  list-style: none;
  position: relative;
  &:after {
    content: " ";
    width: 70px;
    display: block;
    position: absolute;
    bottom: -2rem;
    left: 50%;
    margin-left: -35px;
    height: 2px;
    background-color: $grey-6; }
  li {
    display: inline-block;
    margin: 0 1rem;
    @include breakpoint($phone) {
      margin: 10px 0;
      display: block; }
    a {
      border: 2px solid $share-inactive;
      color: $share-inactive;
      display: block;
      @include transition(all 0.35s);
      @include lato-bold;
      @include fontsize-rem(14px);
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      min-width: 150px;
      text-align: center;
      &:hover {
        border-color: $share-active;
        color: $share-active;
        text-decoration: none; } } } }
