/* -------------------------
  Slider product style
  --------------------------- */
.product-gallery {
  width: 350px;
  margin: 0 auto;
  .gallery-cell {
    width: 350px; } }
.gallery-nav {
  margin-top: 10px;
  .gallery-cell {
    width: 70px;
    height: 70px;
    margin: 5px;
    opacity: 0.6;
    &.is-nav-selected {
      opacity: 1; }
    img {
      max-width: 100%;
      height: auto; } } }
